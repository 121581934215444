import { colors } from '@welend/apple-shopping-cart-ui-library'

import styled from 'styled-components'
interface LinkWithQueryProps {
  pathname: string
  query: any
}

interface LinkProps {
  id?: string
  href?: string | undefined
  as?: string | undefined
  hrefWithQuery?: LinkWithQueryProps
  children: any
  dark?: boolean
  className?: string
  onClick?: () => void
  theme?: string | string[] | undefined
  locale?: string | string[] | undefined
  props?: any
  shallow?: boolean
}

const StyledLink = styled.a<{ dark?: boolean }>`
  ${({ dark }) => (dark ? `color: ${colors.brandBlack}; cursor: pointer` : `cursor: pointer;`)}
`

const Link = ({ id, children, dark, className, onClick, href, ...props }: LinkProps): any => {
  return (
    <StyledLink
      id={id}
      href={href}
      dark={dark}
      aria-label={props?.['aria-label']}
      data-test={props?.['data-test']}
      data-id={props?.['data-id']}
      className={className}
      onClick={onClick}>
      {children}
    </StyledLink>
  )
}

export { Link }
