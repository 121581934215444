import { InitialState } from '@src/types/store/auth'
import ClientOAuth2 from 'client-oauth2'
import { isBefore, parseJSON } from 'date-fns'

export default function getOauthClient({
  clientId,
  clientSecret,
  state,
  redirectUri,
  accessTokenUri,
  authorizationUri
}: ClientOAuth2.Options): ClientOAuth2 {
  const options = {
    clientId,
    clientSecret,
    scopes: ['user_welab_pay'],
    state,
    redirectUri,
    accessTokenUri,
    authorizationUri
  }
  return new ClientOAuth2(options)
}

export function checkAuthenticated(accessToken: string | null, expiresAt: number | null): boolean {
  const tokenExpired = Date.now() > (expiresAt || Date.now())
  return !!accessToken && !tokenExpired
}

export function checkTempTokenExpire(expireAt: InitialState['tempTokenExpireAt']): boolean {
  return isBefore(new Date(), parseJSON(expireAt))
}
