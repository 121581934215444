import { i18n } from '@src/lib/i18n'
import { RootState } from '@src/reducers/rootReducer'
import { Progress } from '@welend/apple-shopping-cart-ui-library'
import { useSelector } from 'react-redux'

const ProgressComponent = (): JSX.Element => {
  const { title, currentStep, stepNames } = useSelector((state: RootState) => state.progress)

  return stepNames && stepNames.length > 0 ? (
    <Progress
      currentStep={currentStep}
      stepNames={stepNames?.map((x, i) => ({
        ...x,
        name: i18n.t(x.name, { count: i })
      }))}
      LinkComponent={({ children }) => {
        return <a href="#">{children}</a>
      }}
      title={i18n.t(title || '', { count: currentStep })}
      dropdownDisabled={true}
    />
  ) : (
    <></>
  )
}

export default ProgressComponent
