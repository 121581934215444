import { changeAppReady, changeInit, changeLoading } from '@src/actions/appActions'
import { loginUser, withToken } from '@src/actions/authActions'
import { changeMallAction } from '@src/actions/mallAction'
import {
  createMerchantCreditLine,
  getDeclarationVersion,
  getPersonalInfo,
  pollWorkFlow,
  pollWorkFlowStop,
  submitOrderCheckout,
  updateOrderId
} from '@src/actions/orderActions'
import { changeLang, getUser, updateUserAgent } from '@src/actions/userActions'
import Body from '@src/components/Layout/Body'
import FooterComponent from '@src/components/Layout/FooterComponent'
import HeaderComponent from '@src/components/Layout/HeaderComponent'
import config from '@src/config'
import { LOAN_FORM_PATH, MERCHANT_CREDIT_LINE_STATE, ONBOARD_STATE } from '@src/lib/constant'
import { isMobile } from '@src/lib/helper'
import { Router, i18n } from '@src/lib/i18n'
import { checkAuthenticated, checkTempTokenExpire } from '@src/lib/oAuth'
import ErrorPage from '@src/pages/error'
import { RootState } from '@src/reducers/rootReducer'
import { get } from 'lodash'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

interface Props {
  children: React.ReactNode
}

export const Auth = ({ children }: Props): JSX.Element => {
  const { REDIRECT_TO_AIP_TIMEOUT, REDIRECT_TO_AIP_POLLING_INTERVAL } = config

  const router = useRouter()
  const { token, expireAt } = router.query
  const isAuthenticated = useSelector((state: RootState) => {
    const { accessToken, expiresAt } = state.auth
    return token || checkAuthenticated(accessToken, expiresAt)
  })
  const [firstInit, setFirstInit] = useState(true)

  const {
    order: {
      form: {
        personalInformation: { tuPulledWithin14Days, postAip }
      },
      workflow: {
        personalInfoCompleted,
        residentialInfoCompleted,
        bankAccountStatus: { success: bankAccountCompleted },
        hkidStatus: { success: hkidCompleted },
        kbaStatus: { success: kbaCompleted },
        onboardState,
        merchantCreditLine
      },
      hasApplied,
      order: { orderId }
    }
  } = useSelector((state: RootState) => state, shallowEqual)
  const userState = useSelector((state: RootState) => state.user.userState)
  const isUserReady = useSelector((state: RootState) => state.app.user)
  const isOrderReady = useSelector((state: RootState) => state.app.order)
  const isDeclarationReady = useSelector((state: RootState) => state.app.declaration)
  const isOptionReady = useSelector((state: RootState) => state.app.option)
  const isPageReady = useSelector((state: RootState) => state.app.page)
  const isAppReady = useSelector((state: RootState) => state.app.ready)
  const isUserAgentReady = useSelector((state: RootState) => state.user.userAgent)
  const isMallReady = useSelector((state: RootState) => state.mall.mallReady)
  const mallState = useSelector((state: RootState) => state.mall.mallState)

  const {
    location: { pathname }
  } = useSelector((state: RootState) => state.router)

  const redirectURI = get(mallState, 'redirectURI', null)

  const isOnBoardedProfile = onboardState === ONBOARD_STATE.succeeded
  const merchantCreditLineCompleted = merchantCreditLine === MERCHANT_CREDIT_LINE_STATE.completed
  const merchantCreditLineExisted = merchantCreditLine === MERCHANT_CREDIT_LINE_STATE.existed
  const hasOnBoardedCurrentMerchant = isOnBoardedProfile && merchantCreditLineCompleted
  const submitTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)

  const redirect = useCallback(
    (url: string, pathname: string) => {
      const targetUrl = `${url}`
      if (!pathname.endsWith(targetUrl) || router.asPath != router.pathname) {
        //is same page and has query param will be redirect
        Router.push(url, undefined, { shallow: true })
      }
    },
    [router.asPath, router.pathname]
  )

  const dispatch = useDispatch()

  const isValidPath = useCallback((x) => x === pathname, [pathname])

  const dismissPollingAndLoading = useCallback(() => {
    dispatch(changeLoading({ isShow: false }))
    dispatch(pollWorkFlowStop())
    if (submitTimeout.current) {
      clearTimeout(submitTimeout.current)
    }
  }, [dispatch])

  const handleCurrentMerchantOnBoarded = useCallback(() => {
    const validAdditionalPath: Array<LOAN_FORM_PATH> = [
      LOAN_FORM_PATH.agreement,
      LOAN_FORM_PATH.otp
    ]
    dismissPollingAndLoading()
    if (validAdditionalPath.find(isValidPath)) {
      redirect(pathname, pathname)
    } else if (!tuPulledWithin14Days && !hasApplied) {
      redirect(LOAN_FORM_PATH.review, pathname)
    } else if (orderId && redirectURI) {
      dispatch(submitOrderCheckout(orderId))
    } else if (!orderId) {
      redirect(LOAN_FORM_PATH.success, pathname)
    } else {
      redirect(LOAN_FORM_PATH.error, pathname)
    }
  }, [
    dispatch,
    dismissPollingAndLoading,
    isValidPath,
    redirectURI,
    orderId,
    pathname,
    redirect,
    hasApplied,
    tuPulledWithin14Days
  ])

  const firePollingAndLoading = useCallback(() => {
    // Display the loading screen
    dispatch(changeLoading({ isShow: true, message: i18n.t('loading') }))
    // Keep pulling the workflow for every 5 sec
    dispatch(pollWorkFlow(REDIRECT_TO_AIP_POLLING_INTERVAL))
    submitTimeout.current = setTimeout(() => {
      dispatch(pollWorkFlowStop())
      dispatch(changeLoading({ isShow: false }))
      redirect(LOAN_FORM_PATH.generalReject, pathname)
    }, REDIRECT_TO_AIP_TIMEOUT)
  }, [REDIRECT_TO_AIP_TIMEOUT, REDIRECT_TO_AIP_POLLING_INTERVAL, dispatch, pathname, redirect])

  const handleCurrentMerchantNotOnBoarded = useCallback(() => {
    if (postAip && hasApplied) {
      firePollingAndLoading()
    }
    // dispatch apply credit line before redirect
    if (!tuPulledWithin14Days) {
      redirect(LOAN_FORM_PATH.review, pathname)
    } else {
      dispatch(createMerchantCreditLine())
    }
  }, [
    dispatch,
    redirect,
    hasApplied,
    tuPulledWithin14Days,
    pathname,
    postAip,
    firePollingAndLoading
  ])

  const handlePersonalInfoAndResidentialInfoCompleted = useCallback(() => {
    const aipValidAdditionalPath: Array<LOAN_FORM_PATH> = [
      LOAN_FORM_PATH.otp,
      LOAN_FORM_PATH.kba,
      LOAN_FORM_PATH.hkid,
      LOAN_FORM_PATH.upload,
      LOAN_FORM_PATH.paymentAccount,
      LOAN_FORM_PATH.additionalInformation,
      LOAN_FORM_PATH.hkidQRCode
    ]
    switch (onboardState) {
      case ONBOARD_STATE.incomplete:
        redirect(LOAN_FORM_PATH.review, pathname)
        break
      case ONBOARD_STATE.applied:
        firePollingAndLoading()
        return () => {
          dismissPollingAndLoading()
        }
        break
      case ONBOARD_STATE.aip:
        dispatch(changeLoading({ isShow: false }))
        if (!merchantCreditLineExisted) {
          if (!hasApplied && tuPulledWithin14Days) {
            dispatch(createMerchantCreditLine())
          } else if (!tuPulledWithin14Days && !hasApplied) {
            redirect(LOAN_FORM_PATH.review, pathname)
          }
        } else {
          if (postAip && !hasApplied && !tuPulledWithin14Days) {
            redirect(LOAN_FORM_PATH.review, pathname)
          } else if (aipValidAdditionalPath.find(isValidPath)) {
            return redirect(pathname, pathname)
          } else {
            return redirect(LOAN_FORM_PATH.additionalInformation, pathname)
          }
        }
        break
      case ONBOARD_STATE.completed:
        if (!tuPulledWithin14Days && !hasApplied) {
          redirect(LOAN_FORM_PATH.review, pathname)
        } else if (postAip && !tuPulledWithin14Days && hasApplied) {
          return
        } else {
          redirect(LOAN_FORM_PATH.generalReject, pathname)
        }
        break
      default:
        redirect(LOAN_FORM_PATH.error, pathname)
        break
    }
  }, [
    dispatch,
    isValidPath,
    merchantCreditLineExisted,
    onboardState,
    pathname,
    redirect,
    tuPulledWithin14Days,
    firePollingAndLoading,
    dismissPollingAndLoading,
    postAip,
    hasApplied
  ])

  useEffect(() => {
    const isMobileDevice = {
      userAgent: navigator.userAgent,
      isMobile: isMobile(navigator.userAgent)
    }
    let state = {}
    let mall = {}
    let order = {}
    if (userState && userState != '' && typeof window != 'undefined') {
      state = JSON.parse(atob(userState))
      mall = get(state, 'mall', {})
      order = get(state, 'order', {})
      dispatch(changeMallAction(mall))
      dispatch(updateOrderId(order))
    }

    const readyCheckList = [
      { value: isUserReady, action: getUser() },
      { value: isAuthenticated, action: loginUser() },
      { value: isOrderReady, action: getPersonalInfo() },
      { value: isDeclarationReady, action: getDeclarationVersion() },
      { value: isOptionReady, action: changeLang() },
      { value: isUserAgentReady, action: updateUserAgent(isMobileDevice) },
      { value: isMallReady, action: changeMallAction(mall) },
      { value: isPageReady, action: changeInit() }
    ]

    if (token) {
      if (checkTempTokenExpire(expireAt as string)) {
        dispatch(withToken())
        dispatch(updateUserAgent(isMobileDevice))
        dispatch(changeAppReady())
      }
    } else {
      for (const { value, action } of readyCheckList) {
        if (!value) {
          dispatch(action)
          break
        }
      }
    }
  }, [
    dispatch,
    userState,
    isAppReady,
    isPageReady,
    isOptionReady,
    isUserReady,
    isOrderReady,
    isDeclarationReady,
    isUserAgentReady,
    isMallReady,
    token,
    expireAt,
    isAuthenticated
  ])

  useEffect(() => {
    if (!isAppReady) {
      return
    }

    if (!Object.values(LOAN_FORM_PATH).find(isValidPath)) {
      redirect(LOAN_FORM_PATH.error, pathname) //is include the Loan Path
      return
    }

    const highPriority: Array<LOAN_FORM_PATH> = [LOAN_FORM_PATH.error, LOAN_FORM_PATH.generalReject] //error and fail path

    if (highPriority.find(isValidPath)) {
      redirect(pathname, pathname)
      return
    }
    // When user is passed onboarding of this merchant...
    if (hasOnBoardedCurrentMerchant) {
      return handleCurrentMerchantOnBoarded()
    }

    if (isOnBoardedProfile && !merchantCreditLineCompleted) {
      return handleCurrentMerchantNotOnBoarded()
    }
    if (!personalInfoCompleted) {
      // When user is not passed onboarding workflow before, follow the workflow
      redirect(LOAN_FORM_PATH.personalInformation, pathname)
    } else if (!residentialInfoCompleted) {
      redirect(LOAN_FORM_PATH.residentialInformation, pathname)
    } else if (personalInfoCompleted && residentialInfoCompleted) {
      return handlePersonalInfoAndResidentialInfoCompleted()
    }
  }, [
    isAppReady,
    isAuthenticated,
    personalInfoCompleted,
    residentialInfoCompleted,
    onboardState,
    pathname,
    merchantCreditLineCompleted,
    hasOnBoardedCurrentMerchant,
    isOnBoardedProfile,
    redirect,
    dispatch,
    isValidPath,
    handlePersonalInfoAndResidentialInfoCompleted,
    handleCurrentMerchantOnBoarded,
    handleCurrentMerchantNotOnBoarded
  ])

  useEffect(() => {
    return () => {
      if (submitTimeout.current) {
        clearTimeout(submitTimeout.current)
      }
      dispatch(changeLoading({ isShow: false }))
      dispatch(pollWorkFlowStop())
    }
  }, [dispatch])

  useEffect(() => setFirstInit(false), []) //firstInit don't show error page

  // TODO: if click header component logout, will fire the below before redirect
  if (!firstInit && !isAuthenticated) {
    return (
      <>
        <HeaderComponent />
        <Body>
          <ErrorPage />
        </Body>
        <FooterComponent />
      </>
    )
  } else if (isAppReady) {
    return <>{children}</>
  } else {
    return <div />
  }
}

export default Auth
