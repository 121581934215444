import { Text } from '@welend/apple-shopping-cart-ui-library'
import { kebabCase } from 'lodash'
import styled from 'styled-components'

interface Props {
  label?: string
  value?: string | number | null
  bold?: boolean
  name?: string
}

const InlineItemWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
  width: 100%;
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  flexbasis: 50%;
  justify-content: flex-start;
`

const Label = styled(Text)`
  color: #93939a;
`

const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  flexbasis: 50%;
  justify-content: flex-end;
`

const Value = styled(Text)<{ bold: boolean }>`
  font-weight: ${(props) => (props.bold ? 700 : 400)};
  text-align: right;
`

const InlineItem = (props: Props) => {
  const { bold, label, value, name } = props

  return (
    <InlineItemWrapper>
      <LabelWrapper>
        <Label>{label}</Label>
      </LabelWrapper>
      <ValueWrapper>
        <Value bold={bold} data-test={kebabCase(name) || kebabCase(label)}>
          {value}
        </Value>
      </ValueWrapper>
    </InlineItemWrapper>
  )
}

export default InlineItem
