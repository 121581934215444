import { RootState } from '@src/reducers/rootReducer'
import { themes } from '@welend/apple-shopping-cart-ui-library'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'

interface Props {
  children: React.ReactNode
}

export const Theme = ({ children }: Props): JSX.Element => {
  const {
    mallState: { theme }
  } = useSelector((state: RootState) => state.mall)
  return <ThemeProvider theme={themes[theme ? theme : 'default']}>{children}</ThemeProvider>
}

export default Theme
