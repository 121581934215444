import { InitialState, UserActionTypes } from '@src/types/store/user'

export const CHANGE_LANG = 'CHANGE_LANG'
export const CHANGE_LANG_SUCCESS = 'CHANGE_LANG_SUCCESS'
export const CHANGE_LANG_FAILURE = 'CHANGE_LANG_FAILURE'
export const UPDATE_LANG_SUCCESS = 'UPDATE_LANG_SUCCESS'
export const UPDATE_LANG = 'UPDATE_LANG'
export const GET_USER = 'GET_USER'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAILURE = 'GET_USER_FAILURE'
export const UPDATE_USER_AGENT = 'UPDATE_USER_AGENT'
export const UPDATE_USER_STATE = 'UPDATE_USER_STATE'

export const INITIAL_STATE: InitialState = {
  lang: '',
  userMobile: '',
  userEmail: '',
  applicationId: '',
  btOfferId: '',
  userAgent: '',
  isMobile: false,
  trackerId: '',
  userType: '',
  userState: ''
}

export default function userReducer(
  state: InitialState = INITIAL_STATE,
  action: UserActionTypes
): InitialState {
  switch (action.type) {
    case UPDATE_LANG_SUCCESS:
      return {
        ...state,
        lang: action.payload.lang
      }
    case GET_USER_SUCCESS:
      return {
        ...state,
        ...action.payload.user
      }
    case UPDATE_USER_AGENT:
      return {
        ...state,
        ...action.payload.userAgent
      }
    case UPDATE_USER_STATE:
      return {
        ...state,
        userState: action.payload.userState
      }
    default:
      return state
  }
}
