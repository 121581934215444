import { SubTitle } from '@welend/apple-shopping-cart-ui-library'
import styled from 'styled-components'
interface Props {
  title?: string
  actions?: React.ReactNode
  edit?: React.ReactNode
  highlight?: boolean
  actionBg?: boolean
  smallTitle?: boolean
  children: React.ReactNode
}

const CardWrapper = styled.div<{ highlight: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: ${(props) => (props.highlight ? '2px solid #FB5555' : null)};
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 50%;
  justify-content: flex-start;
`

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 50%;
  justify-content: flex-end;
`

const Body = styled.div`
  margin-bottom: 24px;
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
`

const Footer = styled.div<{ actionBg: boolean }>`
  background-color: ${(props) => (props.actionBg ? '#D2D2D7' : 'background')};
  padding: 24px 32px;
  width: 100%;
`

export const Card = ({
  title,
  actions,
  edit,
  highlight = false,
  actionBg = false,
  smallTitle = true,
  children
}: Props): JSX.Element => {
  return (
    <CardWrapper highlight={highlight}>
      {title && (
        <Header>
          <TitleWrapper>
            <SubTitle fontWeight="bold" typeface={smallTitle ? 'small' : 'large'}>
              {title}
            </SubTitle>
          </TitleWrapper>
          <ActionWrapper>{edit}</ActionWrapper>
        </Header>
      )}
      <Body>{children}</Body>
      {actions && <Footer actionBg={actionBg}>{actions}</Footer>}
    </CardWrapper>
  )
}

export default Card
