import { statusStrings } from '@src/lib/constant'
import styled from 'styled-components'
interface Props {
  children: React.ReactNode
  mt?: string
  status?: statusStrings
  color?: string
  borderColor?: string
  backgroundColor?: string
}

interface AlertContainerProps {
  styles: {
    color: string
    backgroundColor: string
    borderColor: string
  }
  mt?: string
}

const AlertContainer = styled.div<AlertContainerProps>`
  color: ${({ styles }) => styles.color};
  padding: 8px;
  background-color: ${({ styles }) => styles.backgroundColor};
  border: 2px solid ${({ styles }) => styles.borderColor};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 100%;
  margin-top: ${({ mt }) => (mt ? mt : '0px')};
`

export const Alert = (props: Props): JSX.Element => {
  const {
    children,
    mt,
    status = 'info',
    color = '#f28021',
    backgroundColor = '#fff4e3',
    borderColor = '#ffb300',
    ...rest
  } = props
  const STATUSES = {
    info: {
      color,
      backgroundColor,
      borderColor
    },
    error: { color: '#db1c3f', backgroundColor: '#ffe2e4', borderColor: '#db1c3f' }
  }

  return (
    <AlertContainer mt={mt} styles={STATUSES[status]} {...rest}>
      {children}
    </AlertContainer>
  )
}

export default Alert
