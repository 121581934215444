import { Confirm, themes } from '@welend/apple-shopping-cart-ui-library'

interface Props {
  isOpen: boolean | false
  title?: string
  content?: React.ReactNode
  buttonText: string
  cancelButtonText: string
  onSubmit?: () => void
  onClose?: () => void
}

const Dialog = (props: Props): JSX.Element => {
  const { isOpen, title, content, buttonText, cancelButtonText, onSubmit, onClose } = props

  return (
    <>
      {isOpen ? (
        <Confirm
          title={title}
          message={content}
          buttonText={buttonText}
          cancelButtonText={cancelButtonText}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      ) : null}
    </>
  )
}

export default Dialog
