import { KbaQuestions } from '@src/components'
import { Spinner } from '@src/components/Spinner'
import { GTM_ACTION, GTM_EVENT, LOAN_FORM_PATH } from '@src/lib/constant'
import { trackEvent } from '@src/lib/gtm'
import { Router, i18n } from '@src/lib/i18n'
import { KbaQuestion, KbaStatus } from '@src/types/store/kba'
import {
  Button,
  GridItem,
  SvgIdentityVerificationFailure as IdentityVerificationFailIcon,
  SvgIdentityVerificationTick as IdentityVerificationIcon,
  SvgCheck64 as IdentityVerificationSuccessIcon,
  colors,
  mediaQueries
} from '@welend/apple-shopping-cart-ui-library'
import { padStart } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

interface Props {
  questions: KbaQuestion[]
  state: KbaStatus['state']
  expectedQuestionCount: KbaStatus['expectedQuestionCount']
  currentAttempt: KbaStatus['currentAttempt']
  onKbaStart: () => unknown
  onKbaAnswered: (questionId: string | number, answerId: string | number) => unknown
  onClose?: () => unknown // handle simple form close only, from ResponsiveModel
  onDropOff?: () => unknown // handle intentional drop off with form close, from ResponsiveModel
  onTimeOut?: () => unknown // handle form timeout
  kbaReady: boolean
}

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback: () => null, delay: null | number) {
  const savedCallback = useRef<() => null>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback !== undefined && savedCallback.current !== undefined) {
        savedCallback.current()
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const KbaFormContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  row-gap: 24px;
  justify-items: center;
  padding-left: 32px;
  padding-right: 32px;
`

const CountDownTimerContainer = styled.div`
  background: ${(props) =>
    props.theme?.welabPayUi?.countDownBackground
      ? `${props.theme.welabPayUi?.countDownBackground};`
      : '#fff4e3'};
  border-radius: 3px;
  width: 150px;
  height: 56px;
  margin-left: auto;
  margin-right: auto;
`

const CountDownTimer = styled.div`
  text-align: center;
  color:  ${(props) => (props.theme?.fontColor ? `${props.theme.fontColor};` : `${colors.orange};`)}
  font-weight: 500;
  font-size: 36px;
  margin: 8px 23px;
`

const HeaderTitle = styled.div`
  color: ${colors.black};
  font-weight: bold;
  font-size: 32px;
  @media ${mediaQueries.mediumMobileBreakPoint} {
    font-size: 40px;
  }
`

const SpinnerWrapper = styled.span`
  margin-left: 4px;
`

const KbaForm = (props: Props) => {
  const { questions, state, expectedQuestionCount, onKbaAnswered, onClose, onTimeOut } = props
  let { currentAttempt } = props
  currentAttempt = currentAttempt || 0
  const [questionReady, setQuestionReady] = useState<boolean>(false)
  const [started, setStarted] = useState<boolean>(false)
  const [remainingTime, setRemainingTime] = useState<number>(0)
  const [completed, setCompleted] = useState<boolean>(false)

  const startKba = () => {
    trackEvent(GTM_EVENT.customEvent, GTM_ACTION.cta, 'cta_name: kba_start')
    setStarted(true)
    setCompleted(false)
    props.onKbaStart()
  }

  const timesup = () => {
    if (onTimeOut) {
      onTimeOut()
    }
  }

  useInterval(
    () => {
      setRemainingTime(remainingTime - 1)
      return null
    },
    remainingTime == 0 ? null : 1000
  )

  useEffect(() => {
    if (remainingTime == 0 && questionReady) {
      timesup()
    }
  }, [remainingTime, questionReady])

  useEffect(() => {
    if (questions.length) {
      setQuestionReady(true)
      // not actual use for the timer, all the drop off service is called by backend
      setRemainingTime(questions.length * 60)
    } else {
      setStarted(false)
    }
  }, [questions.length])

  const completeForm = () => {
    setRemainingTime(0)
    setQuestionReady(false)
    setCompleted(true)
  }
  const secondToClock = (second: number): string => {
    return `${padStart(Math.floor(second / 60).toString(), 2, '0')}:${padStart(
      (second % 60).toString(),
      2,
      '0'
    )}`
  }
  const renderHeaderSection = (
    title: string,
    icon:
      | typeof IdentityVerificationIcon
      | typeof IdentityVerificationFailIcon
      | typeof IdentityVerificationSuccessIcon,
    description: string,
    actionBtn: React.ReactElement
  ) => (
    <>
      <HeaderTitle>{title}</HeaderTitle>
      <GridItem style={{ textAlign: 'center' }}>{icon()}</GridItem>
      <div
        dangerouslySetInnerHTML={{
          __html: description
        }}
      />
      {actionBtn}
    </>
  )

  const renderActionButton = (action, context, loading = false) => (
    <Button color="primary" variant="contained" fullWidth onClick={action} disabled={loading}>
      {context}
      {loading && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
    </Button>
  )

  const renderInstructionContent = () => {
    if (state == 'manual') {
      return renderHeaderSection(
        i18n.t('kba:kba_failure'),
        IdentityVerificationFailIcon,
        i18n.t('kba:kba_failure_description_with_no_attempt'),
        renderActionButton(props.onClose, i18n.t('common:button_back'))
      )
    }

    if (currentAttempt == 2) {
      const description = i18n.t('kba:kba_failure_description_with_last_attempt', {
        minutes: 5
      })
      const nextAction = renderActionButton(
        startKba,
        i18n.t('common:button_start'),
        (started && questions.length == 0) || !props.kbaReady
      )
      return renderHeaderSection(
        i18n.t('kba:kba_failure'),
        IdentityVerificationFailIcon,
        description,
        nextAction
      )
    }

    if (currentAttempt == 0 || currentAttempt == 1) {
      const nextAction = renderActionButton(
        startKba,
        i18n.t('common:button_start'),
        (started && questions.length == 0) || !props.kbaReady
      )
      return renderHeaderSection(
        i18n.t('kba:title'),
        IdentityVerificationIcon,
        i18n.t('kba:description', { minutes: 5 }),
        nextAction
      )
    }
  }

  const renderCountDownClock = () => (
    <CountDownTimerContainer>
      <CountDownTimer>{secondToClock(remainingTime)}</CountDownTimer>
    </CountDownTimerContainer>
  )

  if (state == 'success') {
    const nextAction = (
      <Button
        color="primary"
        variant="contained"
        fullWidth
        onClick={() => {
          if (onClose) onClose()
        }}>
        {i18n.t('common:button_next')}
      </Button>
    )
    return renderHeaderSection(
      i18n.t('kba:kba_success'),
      IdentityVerificationSuccessIcon,
      i18n.t('kba:kba_success_desp'),
      nextAction
    )
  } else if (
    completed &&
    ((currentAttempt == 1 && state == 'first_attempted') || // First attempted, not yet changed state to second
      (currentAttempt == 2 && state == 'all_attempted')) // second attempted, not yet get final result
  ) {
    Router.push(LOAN_FORM_PATH.additionalInformation, undefined, { shallow: true })
  }

  return (
    <KbaFormContainer>
      {!questionReady && renderInstructionContent()}
      {questionReady && renderCountDownClock()}
      <KbaQuestions
        questions={questions}
        onKbaAnswered={onKbaAnswered}
        questionReady={questionReady}
        onComplete={completeForm}
      />
    </KbaFormContainer>
  )
}

export default KbaForm
