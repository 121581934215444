import {
  Confirm,
  Dialog as DialogContainer,
  Flex,
  Header,
  Text,
  TextButton,
  Title,
  colors
} from '@welend/apple-shopping-cart-ui-library'

import styled, { useTheme } from 'styled-components'

interface Props {
  isOpen: boolean | false
  title?: string
  content?: string
  buttonText?: string
  icon?: string
  themes?: any
  contentThemes?: any
  titleThemes?: any
  onSubmit?: () => void
  onClose?: () => void
}

const TextMessage = styled(Text)`
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0px !important;
`

const StyledTitle = styled(Title)`
  line-height: 29px;
`

const ButtonsContainer = styled(Flex)`
  border-top: 1px solid ${colors.brandLightGrey};
  margin-top: 16px;
  button {
    flex: 0 100%;
    text-align: center;
    height: 52px;
  }
  width: 100%;
`

interface TextWrapperProps {
  marginTop?: string
}

const TextWrapper = styled.div<TextWrapperProps>`
  width: 100%;
  padding: 0 32px;
  margin-top: ${(p) => (p.marginTop ? `${p.marginTop}` : '24px')};
`

const ContentWrapper = styled(Flex)``

const MessageDialog = (props: Props): JSX.Element => {
  const { isOpen, title, content, buttonText, onClose, icon, themes, contentThemes, titleThemes } =
    props

  const theme = useTheme()
  const DialogTheme = {
    justifyContent: 'center',
    flexflow: 'column',
    alignItems: 'center',
    padding: '24px 0 0 0',
    borderRadius: '16px',
    color: theme.activeColor ?? colors.orange
  }

  return (
    <>
      {isOpen ? (
        <DialogContainer icon={icon} theme={themes ? themes : DialogTheme}>
          {title && (
            <TextWrapper marginTop={titleThemes?.marginTop}>
              <TextMessage align="start">
                <StyledTitle fontSize={'24px'} align={'center'} fontWeight="bold">
                  {title}
                </StyledTitle>
              </TextMessage>
            </TextWrapper>
          )}
          {content && (
            <TextWrapper>
              <TextMessage align="start">
                <ContentWrapper
                  data-test="errorMessage"
                  dangerouslySetInnerHTML={{
                    __html: content
                  }}
                />
              </TextMessage>
            </TextWrapper>
          )}
          <ButtonsContainer>
            <TextButton type="button" onClick={onClose} fontWeight="bold" data-test="cancel">
              {buttonText}
            </TextButton>
          </ButtonsContainer>
        </DialogContainer>
      ) : null}
    </>
  )
}

export default MessageDialog
