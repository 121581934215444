import { uploadProof, uploadProofClear } from '@src/actions/documentActions'
import { UpdateCompleteBoard, pollWorkFlow, pollWorkFlowStop } from '@src/actions/orderActions'
import { updateProgressAction } from '@src/actions/progressAction'
import { UploadQRCode } from '@src/components'
import config from '@src/config'
import { DOCUMENT_TYPE, GTM_ACTION, GTM_EVENT, LOAN_FORM_PATH } from '@src/lib/constant'
import { trackEvent } from '@src/lib/gtm'
import { isMobile } from '@src/lib/helper'
import { Router, i18n } from '@src/lib/i18n'
import { RootState } from '@src/reducers/rootReducer'
import { InitialState as ProgressProps } from '@src/types/store/progress'
import { useProgress } from '@src/utils/useProgress'
import {
  Button,
  Dialog,
  Guideline,
  HR,
  SvgGuidelineHkidCorrect,
  SvgGuidelineHkidWrong,
  Text,
  Title,
  colors,
  mediaQueries
} from '@welend/apple-shopping-cart-ui-library'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

const Container = styled.div`
  margin: 48px 32px 0 32px;
  @media ${mediaQueries.desktop} {
    max-width: 480px;
    margin: 64px auto 0 auto;
  }
`

const GuidelineContainer = styled.div`
  display: flex;
  > div {
    width: 50%;
    &:first-child {
      padding-right: 5px;
    }
    &:last-child {
      padding-left: 5px;
    }
  }
`
const QRCodeContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`

const TextWrapper = styled.div`
  width: 100%;
  padding: 0 32px 0 32px;
  margin-top: 16px;
`

const TextMessage = styled(Text)`
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0px !important;
  text-align: center;
`

const DialogTheme = {
  justifyContent: 'flex-start',
  flexflow: 'column',
  alignItems: 'center',
  padding: '24px 0 0',
  borderRadius: '16px',
  minHeight: '205px',
  size: {
    sm: {
      width: '343px',
      height: '205px'
    },
    mm: {
      width: '343px',
      height: '205px'
    },
    tablet: {
      width: '343px',
      height: '205px'
    },
    desktop: {
      width: '343px',
      height: '205px'
    }
  }
}

const StyledTitle = styled(Title)`
  font-size: 24px;
  line-height: 29px;
`

const DialogWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
interface Props {
  isFromQRCode?: boolean
  progress: ProgressProps
}

const UploadHkid = (props: Props) => {
  const dispatch = useDispatch()
  useProgress({ dispatch, progress: props.progress })
  const { REDIRECT_TO_AIP_POLLING_INTERVAL } = config
  const isFromQRCode = props.isFromQRCode
  const submitTimeOut = useRef<ReturnType<typeof setTimeout> | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { withToken, tempToken } = useSelector((store: RootState) => store.auth)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const isMobileDevice = useSelector((store: RootState) => isMobile(store.user.userAgent))
  const { lang } = useSelector((state: RootState) => state.user)
  const { order } = useSelector((store: RootState) => store, shallowEqual)
  const {
    workflow: { hkidStatus }
  } = order
  const hkidProcessing = hkidStatus.processing
  const hkidCompleted = hkidStatus.success
  const localHkidFile = useSelector((state: RootState) => {
    return state.document.hkidProof
  })

  const modalConfig = {
    title: i18n.t('hkid:mobile_success_modal_title'),
    content: isMobileDevice
      ? i18n.t('hkid:mobile_success_modal_context')
      : i18n.t('hkid:desktop_success_modal_context')
  }

  const guidelineConfig = {
    correct: {
      type: 'correct',
      title: i18n.t('common:take_photo_do'),
      icon: <SvgGuidelineHkidCorrect />,
      items: [
        i18n.t('common:guideline_correct_variant_1'),
        i18n.t('common:guideline_correct_variant_2'),
        i18n.t('common:guideline_correct_variant_3')
      ]
    },
    wrong: {
      type: 'error',
      title: i18n.t('common:take_photo_do_not'),
      icon: <SvgGuidelineHkidWrong />,
      items: [
        i18n.t('common:guideline_wrong_variant_1'),
        i18n.t('common:guideline_wrong_variant_2'),
        i18n.t('common:guideline_wrong_variant_3')
      ]
    }
  }

  const cleanUpAndRedrect = useCallback(() => {
    dispatch(uploadProofClear())
    Router.push(LOAN_FORM_PATH.additionalInformation, undefined, { shallow: true })
  }, [dispatch])

  useEffect(() => {
    if (localHkidFile) {
      setShowSuccessModal(true)
      const rs: ProgressProps = { ...props.progress, currentStep: 2 }
      dispatch(updateProgressAction(rs))
      //Redirect only if not from QR Code
      if (!isFromQRCode) {
        submitTimeOut.current = setTimeout(() => {
          cleanUpAndRedrect()
        }, 3000)
        return () => {
          if (submitTimeOut.current) {
            clearTimeout(submitTimeOut.current)
          }
        }
      }
    }
  }, [localHkidFile, isFromQRCode])

  useEffect(() => {
    //Poll workflow if desktop versions
    if (!isMobileDevice && !isFromQRCode) {
      if (hkidProcessing || hkidCompleted) {
        dispatch(pollWorkFlowStop())
        Router.push(LOAN_FORM_PATH.additionalInformation, undefined, { shallow: true })
      }
      dispatch(pollWorkFlow(REDIRECT_TO_AIP_POLLING_INTERVAL))
    }
    //Check hkid already passed, block user to resubmit through qrcode
    if (isMobileDevice) {
      if (hkidProcessing || hkidCompleted) {
        if (isFromQRCode) {
          return setShowSuccessModal(true)
        } else {
          dispatch(pollWorkFlowStop())
          Router.push(LOAN_FORM_PATH.additionalInformation, undefined, { shallow: true })
        }
      }
    }

    return () => {
      dispatch(pollWorkFlowStop())
    }
  }, [
    isMobileDevice,
    isFromQRCode,
    hkidProcessing,
    hkidCompleted,
    REDIRECT_TO_AIP_POLLING_INTERVAL,
    dispatch
  ])

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      const file = event.target.files[0]
      if (file) {
        dispatch(uploadProof(DOCUMENT_TYPE.hkid, file))
      }
    }
  }

  return (
    <>
      <Container>
        {isMobileDevice ? (
          <div>
            <Title fontWeight="bold" align="center" typeface="large">
              {i18n.t('hkid:title')}
            </Title>
            <Text mt={4} mb={4} dangerouslySetInnerHTML={{ __html: i18n.t('hkid:description') }} />
            <GuidelineContainer>
              <Guideline {...guidelineConfig.wrong} />
              <Guideline {...guidelineConfig.correct} />
            </GuidelineContainer>
            <div>
              <Button
                id="take-photo"
                fullWidth
                gaTag={{
                  event: GTM_EVENT.customEvent,
                  action: GTM_ACTION.cta,
                  label: 'cta_name: take_photo'
                }}
                onClick={() => {
                  trackEvent(GTM_EVENT.customEvent, GTM_ACTION.cta, 'cta_name: take_photo'),
                    (inputRef.current as HTMLInputElement).click()
                }}>
                {i18n.t('button_take_photo', { var: i18n.t('hkid:button_take_photo_var') })}
              </Button>
            </div>
            <div>
              <input
                id={`input-file-hkid`}
                ref={inputRef}
                type="file"
                accept="image/*"
                capture={true}
                onChange={handleChange}
                hidden={true}
                data-test="inputFileHkid"
              />
            </div>
          </div>
        ) : (
          <div>
            <Title fontWeight="bold" align="center" typeface="large">
              {i18n.t('hkid:qr_code_title')}
            </Title>
            <Text mt={4} align="center">
              {i18n.t('hkid:qr_code_description')}
            </Text>
            <QRCodeContainer>
              <UploadQRCode
                path={LOAN_FORM_PATH.uploadToken}
                query={{ type: DOCUMENT_TYPE.hkid, lang }}
              />
            </QRCodeContainer>
          </div>
        )}
        <HR />
        <Text mt={2} typeface="small" color={colors.brandLightGrey}>
          <Text fontWeight="bold">{i18n.t('hkid:remarks_title')}</Text>
          <div dangerouslySetInnerHTML={{ __html: i18n.t('hkid:remarks_context') }} />
        </Text>
      </Container>
      {showSuccessModal && (
        <DialogWrapper>
          <Dialog icon="correct" theme={DialogTheme}>
            <TextWrapper>
              <StyledTitle fontWeight="bold" align="center">
                {modalConfig.title}
              </StyledTitle>
            </TextWrapper>
            <TextWrapper>
              <TextMessage align="start">
                <div>{modalConfig.content}</div>
              </TextMessage>
            </TextWrapper>
          </Dialog>
        </DialogWrapper>
      )}
    </>
  )
}

export default UploadHkid
