import { GTM_ACTION, GTM_EVENT } from '@src/lib/constant'
import { trackEvent } from '@src/lib/gtm'
import { i18n } from '@src/lib/i18n'
import { Option } from '@src/types/store/option'
import {
  Dropdown,
  Grid,
  GridItem,
  Input,
  SubTitle,
  Text,
  mediaQueries
} from '@welend/apple-shopping-cart-ui-library'
import { FormikProps } from 'formik'
import { find, get } from 'lodash'
import React, { useEffect } from 'react'
import styled from 'styled-components'

interface Props {
  formik: FormikProps<any>
  splitAddress?: boolean
  option: {
    nationality: Option[]
    businessNature: Option[]
    monthlyIncomeRange: {
      name: number[]
      id: string
    }[]
    district: {
      name: string
      children?: Option[] | undefined
    }[]
    newDistrict: {
      name: string
      children?: Option[] | undefined
    }[]
  }
  fullWidthMonthlyIncome: boolean
  error?: boolean
  mobile?: boolean
}

interface InputProps {
  isDisable?: boolean
}

type AddressFeild = {
  label: string
  name: string
  type?: string
}

const FormInput = styled(Input)<InputProps>`
  ${(props) => `
  overflow: hidden;
  ${
    props.isDisable &&
    `
    input {
      pointer-events:none;
    }
   `
  }
  `};
`
const StyledGridItem = styled(GridItem)`
  ${(props) => props.type === '1of1' && `width: 100%;`}
  ${(props) =>
    props.type === '1of2' &&
    `
    width: 100%;
    @media ${mediaQueries.desktop} {
      width: 50%;
      flex: 0 0 auto;
    }
`}
`

const StyledGrid = styled(Grid)`
  ${(props) => props.mb && `margin-bottom: ${props.mb * 2}px;`}
  ${(props) => props.mt && `margin-top: ${props.mt * 2}px;`}
  ${StyledGridItem} + ${StyledGridItem} {
    margin-top: 8px;
    @media ${mediaQueries.desktop} {
      margin-top: 0;
    }
  }
  @media ${mediaQueries.desktop} {
    ${(props) => props.mb && `margin-bottom: ${props.mb * 4}px;`}
    ${(props) => props.mt && `margin-top: ${props.mt * 4}px;`}
  }
`

const StyledGridItem1Of3 = styled(GridItem)`
  width: 33.3%;
  ${(props) => props.pr && `padding-right: ${props.pr * 2}px;`}
  ${(props) => props.pl && `padding-left: ${props.pl * 2}px;`} 
  @media ${mediaQueries.desktop} {
    ${(props) => props.pr && `padding-right: ${props.pr * 4}px;`}
    ${(props) => props.pl && `padding-left: ${props.pl * 4}px;`}
  }
`

const InputDescription = styled(Text)`
  padding-left: 12px;
  padding-right: 12px;
`

const ResidentialForm = ({
  formik,
  option,
  splitAddress,
  fullWidthMonthlyIncome = false,
  error = false,
  mobile = false
}: Props): JSX.Element => {
  const nationalities = option.nationality.map((nationality) => ({
    label: nationality.name,
    value: nationality.id
  }))

  const getMoneyIncomeRange = () => {
    if (formik.values.monthlyIncomeRange === '') {
      // formik.setFieldValue('monthlyIncomeRange', option.value)
      const range = [formik.values.monthlyIncomeLowerBound, formik.values.monthlyIncomeUpperBound]
      const incomeRange = find(option.monthlyIncomeRange, ['name', range])
      if (incomeRange) {
        formik.setFieldValue('monthlyIncomeRange', get(incomeRange, 'id', ''))
        return get(incomeRange, 'id', '')
      }
    }
  }

  const businessNature = option.businessNature.map((jobIndustry) => ({
    label: jobIndustry.name,
    value: jobIndustry.id
  }))

  const monthlyIncomeRange = option.monthlyIncomeRange.map((incomeRange) => ({
    label: `${
      incomeRange.name[0] < incomeRange.name[1]
        ? incomeRange.name.join(' - ')
        : `≥ ${incomeRange.name[0]}`
    }`,
    value: incomeRange.id
  }))

  const districts = option.newDistrict.map((district) => {
    return {
      label: district.name,
      options: district.children?.map((districtChildren) => ({
        label: districtChildren.name,
        value: districtChildren.id
      }))
    }
  })

  const StyledSubTitle = styled(SubTitle)<{ mobile: boolean }>`
    display: none;
    @media ${mediaQueries.desktop} {
      display: ${(props) => (props.mobile ? 'none' : 'block')};
    }
  `

  const handleMonelyIncome = (formik, value) => {
    const incomeRange = find(option.monthlyIncomeRange, ['id', value])
    formik.setFieldValue('monthlyIncomeLowerBound', get(incomeRange, 'name[0]', 0))
    formik.setFieldValue('monthlyIncomeUpperBound', get(incomeRange, 'name[1]', 0))
    formik.setFieldTouched('monthlyIncomeRange')
    formik.setFieldValue('monthlyIncomeRange', value)
  }

  const combineFieldValues = ({
    formik,
    splittedAddressFields,
    event,
    option
  }: {
    formik: FormikProps<any>
    splittedAddressFields: Array<AddressFeild>
    event?: React.ChangeEvent<HTMLInputElement>
    option?: { label: string; value: string }
  }) => {
    let fieldChanged
    let valueChanged
    if (event) {
      fieldChanged = event.target.name
      valueChanged = event.target.value
    }
    if (option) {
      fieldChanged = 'district'
      valueChanged = option.label
    }
    const addressArray = splittedAddressFields.reduce(
      (prev: string[], addressField: AddressFeild) => {
        const formValue = formik.values[addressField.name]

        // If the field to change will become empty after this change event, ignore it
        if (
          (!valueChanged && fieldChanged === addressField.name) ||
          (!formValue && fieldChanged !== addressField.name)
        ) {
          return prev
        }

        if (addressField.type === 'select') {
          const formValue = formik.values[`${addressField.name}_select`]
          prev.push(` ${fieldChanged === addressField.name ? valueChanged : formValue.label}`)
        } else {
          const formValue = formik.values[addressField.name]
          prev.push(
            `${addressField.label} ${fieldChanged === addressField.name ? valueChanged : formValue}`
          )
        }

        return prev
      },
      []
    )

    return addressArray.join(', ')
  }

  const handleUnitFloorBlockChange = (
    formik: FormikProps<any>,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const splittedAddressFields = [
      { label: 'Unit', name: 'unit' },
      { label: 'Floor', name: 'floor' },
      { label: 'Block', name: 'block' }
    ]
    const unitFloorBlock = combineFieldValues({ formik, splittedAddressFields, event })
    formik.setFieldValue('unitFloorBlock', unitFloorBlock, true)
    return handleSplittedAddressChange({ formik, event })
  }

  const handleSplittedAddressChange = ({
    formik,
    event,
    option
  }: {
    formik: FormikProps<any>
    event?: React.ChangeEvent<HTMLInputElement>
    option?: { label: string; value: string }
  }) => {
    const splittedAddressFields = [
      { label: 'Unit', name: 'unit' },
      { label: 'Floor', name: 'floor' },
      { label: 'Block', name: 'block' },
      { label: '', name: 'buildingName' },
      { label: '', name: 'district', type: 'select' }
    ]

    const residentialAddress = combineFieldValues({
      formik,
      splittedAddressFields,
      event,
      option
    }).replace(/’/g, "'")
    formik.setFieldValue('residentialAddress', residentialAddress, false)
    if (event) {
      formik.handleChange(event)
    }
  }

  useEffect(() => {
    if (error) {
      formik.setTouched(
        {
          nationality: true,
          businessNature: true,
          monthlyIncomeRange: true,
          residentialAddress: true
        },
        true
      )
    }
  }, [])

  const renderFormFields = () => {
    return (
      <>
        <StyledSubTitle fontWeight="bold" mb={4} mobile={mobile}>
          {i18n.t('residentialInformation:section1')}
        </StyledSubTitle>
        <StyledGrid mt={4}>
          <StyledGridItem type="1of2" pr={2}>
            <Dropdown
              id="nationality"
              onChange={(option) => {
                formik.setFieldTouched('nationality')
                formik.setFieldValue('nationality', option.value)
                trackEvent(GTM_EVENT.customEvent, GTM_ACTION.formInput, 'name: nationality')
              }}
              value={formik.values.nationality}
              options={nationalities}
              placeholder={i18n.t('residentialInformation:form_nationality_label')}
              error={formik.touched.nationality && formik.errors.nationality}
            />
          </StyledGridItem>
          <StyledGridItem type="1of2" pl={2}>
            <Dropdown
              id="businessNature"
              onChange={(option) => {
                formik.setFieldTouched('businessNature')
                formik.setFieldValue('businessNature', option.value)
                trackEvent(GTM_EVENT.customEvent, GTM_ACTION.formInput, 'name: business_nature')
              }}
              value={
                formik.values.businessNature
                  ? Number(formik.values.businessNature)
                  : formik.values.businessNature
              }
              options={businessNature}
              placeholder={i18n.t('residentialInformation:form_job_industry_label')}
              error={formik.touched.businessNature && formik.errors.businessNature}
            />
          </StyledGridItem>
        </StyledGrid>

        <StyledGrid
          mt={Boolean(formik.errors.nationality) || Boolean(formik.errors.businessNature) ? 8 : 4}>
          <StyledGridItem
            type={fullWidthMonthlyIncome ? '1of1' : '1of2'}
            pr={fullWidthMonthlyIncome ? 0 : 2}>
            <Dropdown
              id="averageMonthlyIncome"
              onChange={(option) => {
                handleMonelyIncome(formik, option.value)
                trackEvent(GTM_EVENT.customEvent, GTM_ACTION.formInput, 'name: monthly_income')
              }}
              value={formik.values.monthlyIncomeRange}
              options={monthlyIncomeRange}
              placeholder={i18n.t('residentialInformation:form_label_monthly_income')}
              error={formik.touched.monthlyIncomeRange && formik.errors.monthlyIncomeRange}
            />
          </StyledGridItem>
        </StyledGrid>

        {splitAddress && (
          <>
            <SubTitle mt={6} fontWeight="bold" typeface="small">
              {i18n.t('residentialInformation:address_subtitle')}
            </SubTitle>

            <Text mt={4}>{i18n.t('residentialInformation:section2Description')}</Text>

            <StyledGrid mt={4} wrap="true">
              <StyledGridItem type="1of2" pr={2}>
                <FormInput
                  type="text"
                  name="unit"
                  onChange={(event) => handleUnitFloorBlockChange(formik, event)}
                  onFocus={() =>
                    trackEvent(
                      GTM_EVENT.customEvent,
                      GTM_ACTION.formInput,
                      'name: residential_unit'
                    )
                  }
                  label={i18n.t('residentialInformation:form_address_unit_label')}
                  onBlur={formik.handleBlur}
                  value={formik.values.unit}
                  data-test="inputUnit"
                  error={formik.touched.unit && formik.errors.unit}
                />
              </StyledGridItem>
              <StyledGridItem type="1of2" pl={2}>
                <FormInput
                  type="text"
                  name="floor"
                  onChange={(event) => handleUnitFloorBlockChange(formik, event)}
                  onFocus={() =>
                    trackEvent(
                      GTM_EVENT.customEvent,
                      GTM_ACTION.formInput,
                      'name: residential_floor'
                    )
                  }
                  label={i18n.t('residentialInformation:form_address_floor_label')}
                  onBlur={formik.handleBlur}
                  value={formik.values.floor}
                  data-test="inputFloor"
                  error={formik.touched.floor && formik.errors.floor}
                />
              </StyledGridItem>
            </StyledGrid>
            <StyledGrid mt={4}>
              <StyledGridItem type="1of2" pr={2}>
                <FormInput
                  type="text"
                  name="block"
                  onChange={(event) => handleUnitFloorBlockChange(formik, event)}
                  label={i18n.t('residentialInformation:form_address_block_label')}
                  onBlur={formik.handleBlur}
                  onFocus={() =>
                    trackEvent(
                      GTM_EVENT.customEvent,
                      GTM_ACTION.formInput,
                      'name: residential_block'
                    )
                  }
                  value={formik.values.block}
                  data-test="inputBlock"
                  error={formik.touched.block && formik.errors.block}
                />
              </StyledGridItem>
              <StyledGridItem type="1of2" pl={2}>
                <FormInput
                  type="text"
                  name="buildingName"
                  onChange={(event) => handleSplittedAddressChange({ formik, event })}
                  label={i18n.t('residentialInformation:form_address_building_name_label')}
                  onBlur={formik.handleBlur}
                  onFocus={() =>
                    trackEvent(
                      GTM_EVENT.customEvent,
                      GTM_ACTION.formInput,
                      'name: residential_building'
                    )
                  }
                  value={formik.values.buildingName}
                  data-test="inputBuildingName"
                  error={formik.touched.buildingName && formik.errors.buildingName}
                />
                <InputDescription typeface="small" mt={1}>
                  {i18n.t('residentialInformation:form_address_building_name_description')}
                </InputDescription>
              </StyledGridItem>
            </StyledGrid>
            <StyledGrid mt={4}>
              <StyledGridItem type="1of2" pr={2}>
                <Dropdown
                  id="district"
                  onChange={(option) => {
                    formik.setFieldTouched('district')
                    handleSplittedAddressChange({ formik, option })
                    formik.setFieldValue('district_select', option)
                    formik.setFieldValue('district', option.value)
                    trackEvent(
                      GTM_EVENT.customEvent,
                      GTM_ACTION.formInput,
                      'name: residential_district'
                    )
                  }}
                  isGroup
                  value={formik.values.district}
                  options={districts}
                  placeholder={i18n.t('residentialInformation:form_address_district_label')}
                  error={formik.touched.district && formik.errors.district}
                />
              </StyledGridItem>
            </StyledGrid>
          </>
        )}

        {!splitAddress && (
          <FormInput
            mt={4}
            type="text"
            name="residentialAddress"
            onChange={formik.handleChange}
            label={i18n.t('residentialInformation:form_address_label')}
            onBlur={formik.handleBlur}
            onFocus={() =>
              trackEvent(
                GTM_EVENT.customEvent,
                GTM_ACTION.formInput,
                'name: residential_full_address'
              )
            }
            value={formik.values.residentialAddress}
            data-test="inputResidentialAddress"
            error={formik.touched.residentialAddress && formik.errors.residentialAddress}
          />
        )}
      </>
    )
  }

  return <>{renderFormFields()}</>
}

export default ResidentialForm
