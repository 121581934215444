import { clearAuth } from '@src/actions/authActions'
import { CartDropdown } from '@src/components/Cart'
import { Dialog } from '@src/components/Dialog'
import { MessageDialog } from '@src/components/MessageDialog'
import config from '@src/config'
import { LOAN_FORM_PATH } from '@src/lib/constant'
import { i18n } from '@src/lib/i18n'
import { RootState } from '@src/reducers/rootReducer'
import { Link } from '@src/utils/link'
import { Header, Logo } from '@welend/apple-shopping-cart-ui-library'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

const DialogTheme = {
  justifyContent: 'center',
  flexflow: 'column',
  alignItems: 'center',
  padding: '24px 0 0 0',
  borderRadius: '0',
  minHeight: '170px',
  size: {
    sm: {
      width: '382px',
      height: '179px'
    },
    mm: {
      width: '382px'
    },
    tablet: {
      width: '414px'
    },
    desktop: {
      width: '414px'
    }
  }
}

const hyperLink = (x, index): JSX.Element => (
  <React.Fragment key={'headerHyperLink' + index}>
    <Link id={x.id} href={x.path} onClick={x.onClick}>
      {x.title}
    </Link>
  </React.Fragment>
)

const LogoLink = ({ href, children, onlyRedirect, clickLinkHandler }) => (
  <Link onClick={() => clickLinkHandler(href, onlyRedirect)}>{children}</Link>
)

const ResellerLogo = ({ href, onlyRedirect, clickLinkHandler }) => (
  <Logo onClick={() => clickLinkHandler(href, onlyRedirect)} />
)

const HeaderComponent = (): JSX.Element => {
  const { APPLE_SHOPPING_CART_HOST } = config
  const dispatch = useDispatch()
  const router = useRouter()
  const {
    form: {
      personalInformation: { englishFirstName, englishLastName }
    }
  } = useSelector((state: RootState) => state.order)
  const {
    mallState: { errorCallbackURI }
  } = useSelector((state: RootState) => state.mall)
  const {
    location: { pathname }
  } = useSelector((state: RootState) => state.router)
  const { accessToken } = useSelector((state: RootState) => state.auth)
  const { locale = i18n.language } = router.query
  const {
    mallState: { theme }
  } = useSelector((state: RootState) => state.mall)
  const shopLink = `${APPLE_SHOPPING_CART_HOST}/${theme}/${locale}/product/list`
  const accountLink = `${APPLE_SHOPPING_CART_HOST}/${theme}/${locale}/account/overview`
  const [isOpenLogout, setIsOpenLogout] = useState(false)
  const [isOpenRedirect, setIsOpenRedirect] = useState(false)
  const [cantClickModal, setCantClickModal] = useState(false)
  const [redirectTargetUri, setRedirectTargetUri] = useState<string>('')
  const [navigationLinks, setNavigationLinks] = useState<Array<any>>([])

  const onBoarding: Array<LOAN_FORM_PATH> = [
    LOAN_FORM_PATH.personalInformation,
    LOAN_FORM_PATH.residentialInformation,
    LOAN_FORM_PATH.review,
    LOAN_FORM_PATH.additionalInformation,
    LOAN_FORM_PATH.uploadToken,
    LOAN_FORM_PATH.kba,
    LOAN_FORM_PATH.hkid,
    LOAN_FORM_PATH.hkidQRCode,
    LOAN_FORM_PATH.paymentAccount
  ]
  const checkout: Array<LOAN_FORM_PATH> = [LOAN_FORM_PATH.agreement, LOAN_FORM_PATH.otp]
  const triggerDialogWrapper = [...onBoarding, ...checkout].find((x) => x === pathname)
  const isCheckout = accessToken && checkout.find((x) => x === pathname)
  const isOnBoarding = accessToken && onBoarding.find((x) => x === pathname)
  const errorTitle = isOnBoarding ? 'header:leave_onboarding_title' : 'header:leave_checkout_title'
  const errorContent = isOnBoarding
    ? 'header:leave_onboarding_content'
    : 'header:leave_checkout_content'

  const clickLinkHandler = (URI, onlyRedirect) => {
    if (onlyRedirect) {
      router.push(URI)
    } else if (isOnBoarding) {
      setCantClickModal(true)
    } else if (isCheckout) {
      setIsOpenRedirect(true)
      setRedirectTargetUri(URI)
    }
  }

  const onSubmitLogout = () => {
    dispatch(clearAuth())
    setIsOpenLogout(false)
    router.replace(decodeURIComponent(errorCallbackURI as string) || shopLink)
  }

  useEffect(() => {
    setNavigationLinks([
      {
        title: i18n.t('header:shop'),
        onClick: () => clickLinkHandler(shopLink, !(isCheckout || isOnBoarding))
      },
      ...(englishLastName && englishFirstName
        ? [
            {
              path: triggerDialogWrapper ? null : accountLink,
              title: i18n.t('header:account'),
              onClick: () => clickLinkHandler(accountLink, !(isCheckout || isOnBoarding))
            },
            {
              id: 'header-fullname',
              title: `${englishLastName} ${englishFirstName}`.trim()
            }
          ]
        : []),
      ...(accessToken
        ? [
            {
              title: i18n.t('header:logout'),
              type: 'signout',
              onClick: () => setIsOpenLogout(true)
            }
          ]
        : [])
    ])
  }, [pathname, locale])

  return (
    <>
      <Header
        title="Subscription"
        cartValue={0}
        navigation={navigationLinks.map(hyperLink)}
        LinkComponent={(props) => (
          <LogoLink
            {...props}
            onlyRedirect={!(isCheckout || isOnBoarding)}
            clickLinkHandler={clickLinkHandler}
          />
        )}
        LogoComponent={(props) => (
          <ResellerLogo
            {...props}
            onlyRedirect={!(isCheckout || isOnBoarding)}
            clickLinkHandler={clickLinkHandler}
          />
        )}
        dropdown={<CartDropdown nav={navigationLinks} logout={setIsOpenLogout} />}
        shopLink={shopLink}
        logoEnabled={false}
      />
      <Dialog
        isOpen={isOpenLogout}
        title={i18n.t(errorTitle)}
        content={i18n.t(errorContent)}
        buttonText={i18n.t('header:leave')}
        cancelButtonText={i18n.t('header:cancel')}
        onSubmit={() => onSubmitLogout()}
        onClose={() => setIsOpenLogout(false)}
      />
      <Dialog
        isOpen={isOpenRedirect}
        title={i18n.t(errorTitle)}
        content={i18n.t(errorContent)}
        buttonText={i18n.t('header:leave')}
        cancelButtonText={i18n.t('header:cancel')}
        onSubmit={() => {
          router.push(redirectTargetUri)
          setIsOpenRedirect(false)
        }}
        onClose={() => setIsOpenRedirect(false)}
      />
      <MessageDialog
        isOpen={cantClickModal}
        title={i18n.t('header:stop_menu')}
        content={''}
        buttonText={i18n.t('common:continue')}
        themes={DialogTheme}
        titleThemes={{ marginTop: '0px' }}
        onClose={() => setCantClickModal(false)}
      />
    </>
  )
}

export default HeaderComponent
