import HkidInput from '@src/components/HkidInput/HkidInput'
import { GTM_ACTION, GTM_EVENT } from '@src/lib/constant'
import { trackEvent } from '@src/lib/gtm'
import { i18n } from '@src/lib/i18n'

import {
  Grid,
  GridItem,
  Input,
  SubTitle,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Text,
  mediaQueries
} from '@welend/apple-shopping-cart-ui-library'
import { FormikProps } from 'formik'
import styled from 'styled-components'

interface Props {
  formik: FormikProps<any>
  fullInfo?: boolean
  mobile?: boolean
  disabledFields?: string[]
}

const StyledGridItem = styled(GridItem)``

const StyledGrid = styled(Grid)<{ mobile: boolean }>`
  ${(props) => props.mb && `margin-bottom: ${props.mb * 2}px;`}
  ${(props) => props.mt && `margin-top: ${props.mt * 2}px;`}
  ${StyledGridItem} + ${StyledGridItem} {
    margin-top: 8px;
    @media ${mediaQueries.desktop} {
      margin-top: ${(props) => (props.mobile ? '8px' : 0)};
    }
  }
  @media ${mediaQueries.desktop} {
    ${(props) => !props.mobile && props.mb && `margin-bottom: ${props.mb * 4}px;`}
    ${(props) => !props.mobile && props.mt && `margin-top: ${props.mt * 4}px;`}
  }
`

const StyledSubTitle = styled(SubTitle)<{ mobile: boolean }>`
  display: none;
  @media ${mediaQueries.desktop} {
    display: ${(props) => (props.mobile ? 'none' : 'block')};
  }
`

const InputDescription = styled(Text)`
  padding-left: 12px;
  padding-right: 12px;
`

const PersonalForm = ({
  formik,
  fullInfo,
  mobile = false,
  disabledFields = []
}: Props): JSX.Element => {
  const handleGenderChange = (value: number | null, formik: FormikProps<any>) => {
    if (value === 0) {
      trackEvent(GTM_EVENT.customEvent, GTM_ACTION.formInput, 'name: gender_male')
      return formik.setFieldValue('gender', '1', false)
    } else if (value === 1) {
      trackEvent(GTM_EVENT.customEvent, GTM_ACTION.formInput, 'name: gender_female')
      return formik.setFieldValue('gender', '2', false)
    } else {
      return formik.setFieldValue('gender', '', false)
    }
  }

  const handleHkidChange = (value: string, formik: FormikProps<any>) => {
    formik.setFieldValue('hkid', value, false)
    formik.validateField('hkid')
    return
  }

  const renderFormFields = () => {
    return (
      <>
        <StyledSubTitle fontWeight="bold" mb={4} mobile={mobile}>
          {i18n.t('personalInformation:section1')}
        </StyledSubTitle>
        {fullInfo && (
          <Input
            mt={4}
            inputType="mobile"
            name="mobile"
            disabled={disabledFields.includes('mobile')}
            onChange={formik.handleChange}
            label={i18n.t('personalInformation:form_mobile_label')}
            onBlur={formik.handleBlur}
            onFocus={() => trackEvent(GTM_EVENT.customEvent, GTM_ACTION.formInput, 'name: mobile')}
            value={formik.values.mobile}
            error={formik.touched.mobile && formik.errors.mobile}
            data-test="inputMobile"
          />
        )}
        <StyledGrid mt={4} wrap="true" mobile={mobile}>
          <StyledGridItem type="1of2" pr={mobile ? 0 : 2} override={mobile}>
            <Input
              inputType="text"
              name="englishLastName"
              disabled={disabledFields.includes('englishLastName')}
              onChange={formik.handleChange}
              label={i18n.t('personalInformation:form_english_last_name_label')}
              onBlur={formik.handleBlur}
              onFocus={() =>
                trackEvent(GTM_EVENT.customEvent, GTM_ACTION.formInput, 'name: last_name')
              }
              value={formik.values.englishLastName}
              error={formik.touched.englishLastName && formik.errors.englishLastName}
              data-test="inputEnglishLastName"
            />
            <InputDescription typeface="small" mt={1}>
              {i18n.t('personalInformation:form_english_last_name_input_description')}
            </InputDescription>
          </StyledGridItem>
          <StyledGridItem type="1of2" pl={mobile ? 0 : 2} override={mobile}>
            <Input
              inputType="text"
              name="englishFirstName"
              disabled={disabledFields.includes('englishFirstName')}
              onChange={formik.handleChange}
              label={i18n.t('personalInformation:form_english_first_name_label')}
              onBlur={formik.handleBlur}
              onFocus={() =>
                trackEvent(GTM_EVENT.customEvent, GTM_ACTION.formInput, 'name: first_name')
              }
              value={formik.values.englishFirstName}
              error={formik.touched.englishFirstName && formik.errors.englishFirstName}
              data-test="inputEnglishFirstName"
            />
            <InputDescription typeface="small" mt={1}>
              {i18n.t('personalInformation:form_english_first_name_input_description')}
            </InputDescription>
          </StyledGridItem>
        </StyledGrid>
        <StyledGrid mt={4} wrap="true" mobile={mobile}>
          <StyledGridItem type="1of2" pr={mobile ? 0 : 2} override={mobile}>
            <Tabs
              defaultIndex={Number(formik.values.gender) - 1}
              onSelect={(index) => {
                handleGenderChange(index, formik)
                formik.setFieldTouched('gender')
              }}>
              <TabList height={'56px'} border={'none'} bgColors={'inherit'}>
                <Tab
                  margin={'0px 10px 0px 0px'}
                  border={'1px solid #e8e8ed'}
                  disabled={false}
                  data-test="inputMale">
                  {i18n.t('personalInformation:form_gender_option_male')}
                </Tab>
                <Tab border={'1px solid #e8e8ed'} data-test="inputFemale">
                  {i18n.t('personalInformation:form_gender_option_female')}
                </Tab>
              </TabList>
              <TabPanel></TabPanel>
              <TabPanel></TabPanel>
            </Tabs>
          </StyledGridItem>
          <StyledGridItem type="1of2" pl={mobile ? 0 : 2} override={mobile}>
            <Input
              type="text"
              inputType="datepicker"
              name="birthday"
              disabled={disabledFields.includes('birthday')}
              onChange={formik.handleChange}
              label={i18n.t('personalInformation:form_birthday_label')}
              onBlur={formik.handleBlur}
              onFocus={() =>
                trackEvent(GTM_EVENT.customEvent, GTM_ACTION.formInput, 'name: date_of_birth')
              }
              value={formik.values.birthday}
              error={formik.touched.birthday && formik.errors.birthday}
              data-test="inputBirthday"
              defaultValue={formik.values.birthday}
            />
          </StyledGridItem>
        </StyledGrid>
        <StyledGrid mt={4} mobile={mobile}>
          <StyledGridItem type="1of2" pr={mobile ? 0 : 2} override={mobile}>
            <HkidInput
              label={i18n.t('personalInformation:form_hkid_label')}
              name="hkid"
              onChange={(value) => handleHkidChange(value, formik)}
              onBlur={formik.handleBlur}
              value={formik.values.hkid}
              error={formik.touched.hkid && formik.errors.hkid}
            />
          </StyledGridItem>
        </StyledGrid>
        <StyledSubTitle fontWeight="bold" mt={4} mb={4} mobile={mobile}>
          {i18n.t('personalInformation:section2')}
        </StyledSubTitle>
        <StyledGrid mt={4} mobile={mobile}>
          <StyledGridItem type="1of2" pr={mobile ? 0 : 2} override={mobile}>
            <Input
              inputType="email"
              name="email"
              disabled={disabledFields.includes('email')}
              onChange={formik.handleChange}
              label={i18n.t('personalInformation:form_email_label')}
              onBlur={formik.handleBlur}
              onFocus={() => trackEvent(GTM_EVENT.customEvent, GTM_ACTION.formInput, 'name: email')}
              value={formik.values.email}
              error={formik.touched.email && formik.errors.email}
              data-test="inputEmail"
            />
            <Text mt={1}>{i18n.t('personalInformation:register_for_apple_care')}</Text>
          </StyledGridItem>
        </StyledGrid>
      </>
    )
  }

  return <>{renderFormFields()}</>
}

export default PersonalForm
