import { Card, InlineItem } from '@src/components'
import EditIcon from '@src/components/Icon/EditIcon'
import { i18n } from '@src/lib/i18n'
import theme from '@src/styles/theme'
import { Text, themes } from '@welend/apple-shopping-cart-ui-library'
import { Fragment } from 'react'
import styled from 'styled-components'

interface Props {
  title: string
  items: Array<{
    label: string
    value: string | number | null | undefined
    require: boolean
    name: string
  }>
  open: string
  openState: string
  onClickEdit: (string) => void
  children: React.ReactNode
  enableEdit: boolean
  color?: string
}

const ButtonWrapper = styled.button<{ color: string }>`
  border: none;
  background: none;
  display: inline-flex;
  align-items: center;
  color: ${({ color }) => color};
  cursor: pointer;
`

const EditIconWrapper = styled(EditIcon)`
  font-weight: bold;
  margin-right: 4px;
`

const ReviewItem = ({
  title,
  items,
  open,
  openState,
  onClickEdit,
  enableEdit,
  color = '#f28021',
  children
}: Props): JSX.Element => {
  return (
    <Fragment>
      {open != openState && (
        <Card
          title={title}
          edit={
            enableEdit ? (
              <ButtonWrapper color={color} onClick={() => onClickEdit(openState)}>
                <EditIconWrapper />
                <Text underline fontWeight="bold" small>
                  {i18n.t('review:edit')}
                </Text>
              </ButtonWrapper>
            ) : null
          }>
          {items.map((object, key) => {
            return (object.value !== '' && object.value) || object.require ? (
              <InlineItem key={key} label={object.label} value={object.value} name={object.name} />
            ) : null
          })}
        </Card>
      )}
      {open == openState && children}
    </Fragment>
  )
}

export default ReviewItem
