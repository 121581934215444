import * as types from '@src/reducers/authReducer'
import {
  ClearAuthAction,
  GetTempTokenAction,
  GetTempTokenFailureAction,
  GetTempTokenSuccessAction,
  LoginDoOAuthAction,
  LoginRedirectedAction,
  LoginStoreTokenAction,
  LoginUserAction,
  OAuthValue,
  TempTokenResponse,
  WithTokenAction
} from '@src/types/store/auth'

export const loginRedirected = (): LoginRedirectedAction => ({
  type: types.LOGIN_REDIRECTED
})

export const loginStoreToken = (OAuthValue: OAuthValue): LoginStoreTokenAction => ({
  type: types.LOGIN_STORE_TOKEN,
  payload: { ...OAuthValue }
})

export const loginDoOAuth = (): LoginDoOAuthAction => ({
  type: types.LOGIN_DO_OAUTH
})

export const loginUser = (): LoginUserAction => ({
  type: types.LOGIN_USER
})

export const getTempToken = (): GetTempTokenAction => ({
  type: types.GET_TEMP_TOKEN
})

export const withToken = (): WithTokenAction => ({
  type: types.WITH_TOKEN
})

export const getTempTokenSuccess = (
  tempToken: TempTokenResponse['token'],
  expireAt: TempTokenResponse['expireAt']
): GetTempTokenSuccessAction => ({
  type: types.GET_TEMP_TOKEN_SUCCESS,
  payload: { tempToken, expireAt }
})

export const getTempTokenFailure = (): GetTempTokenFailureAction => ({
  type: types.GET_TEMP_TOKEN_FAILURE
})

export const clearAuth = (): ClearAuthAction => ({
  type: types.CLEAR_AUTH
})
