import { i18n } from '@src/lib/i18n'
import { Cart } from '@welend/apple-shopping-cart-ui-library'

const CartDropdown = ({ nav, logout }): JSX.Element => {
  const loggedDropdownContent = {
    nav,
    message: i18n.t('header:subscribe_tips'),
    learnMore: {
      title: i18n.t('header:learn_more_about_subscription'),
      path: 'page.html'
    }
  }

  return (
    <Cart
      products={[]}
      subtotal={0}
      content={loggedDropdownContent}
      cartButtonText={i18n.t('header:checkout')}
      cartEmptyText={i18n.t('header:cart_is_empty')}
      moreItemsInfoText={i18n.t('header:more_item')}
      moreItemsInfoSubText={i18n.t('header:more_item')}
      monthInfoText={i18n.t('header:month')}
      overLimit
      subtotalHeadline={i18n.t('header:total_amount')}
      bagBtnAction={logout}
    />
  )
}

export default CartDropdown
