import { i18n } from '@src/lib/i18n'
import { MallActionTypes, MallState } from '@src/types/store/mall'

export const CHANGE_MALL_READY = 'CHANGE_MALL_READY'
export const CHANGE_MALL_ACTION = 'CHANGE_MALL_ACTION'

export const INITIAL_STATE: MallState = {
  mallReady: false,
  mallState: {
    theme: 'default'
  }
}

export default function mallReducer(state = INITIAL_STATE, action: MallActionTypes) {
  switch (action.type) {
    case CHANGE_MALL_READY:
      return {
        ...state
      }
    case CHANGE_MALL_ACTION:
      return {
        ...state,
        mallState: action.payload.mallState,
        mallReady: true
      }
    default:
      return state
  }
}
