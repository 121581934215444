import { GTM_ACTION, GTM_EVENT } from '@src/lib/constant'
import { trackEvent } from '@src/lib/gtm'
import { Flex, Input, mediaQueries } from '@welend/apple-shopping-cart-ui-library'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

const FlexContainer = styled(Flex)`
  width: 100%;
  box-sizing: border-box;
`

const HKidBeforeParenthesisContainer = styled.div`
  min-width: 100px;
  max-width: 244px;
  width: 100%;
  font-size: 16px;
  line-height: 16px;
  @media ${mediaQueries.mediumMobileBreakPoint} {
    min-width: calc(100% - 90px);
    max-width: 390px;
  }
`
const HKidInParenthesisContainer = styled(Flex)`
  width: 90px;
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  ${(props) => props.isOnError && `margin-bottom:  31px`};
  @media ${mediaQueries.desktop} {
    ${(props) => props.isOnError && `margin-bottom: 23px `};
  }
`
const HKidInParenthesisInput = styled(Flex)`
  width: 48px;
  font-size: 16px;
  line-height: 16px;
`

const LeftParenthesis = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 21px;
  padding-left: 16px;
  padding-right: 8px;
`
const RightParenthesis = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 21px;
  padding-left: 8px;
`

const InputField = styled(Input)`
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  input {
    ${(props) => props.padding && `padding: ${props.padding} !important`};
    ${(props) => props.fontSize && `font-size: ${props.fontSize} !important`};
    ${(props) => props.textAlign && `text-align: ${props.textAlign} !important`};
  }
`

const HkidInput = (props): JSX.Element => {
  const { label, name, onChange, onBlur, value, error } = props
  const [beforeParenthesis, setBeforeParenthesis] = useState('')
  const [inParenthesis, setInParenthesis] = useState('')

  const handleHkidBeforeParenthesisChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setBeforeParenthesis(value)
    onChange(`${value}${inParenthesis}`)
  }
  const handleHkidInParenthesisChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setInParenthesis(value)
    onChange(`${beforeParenthesis}${value}`)
  }

  const handleHkidBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateStateValue()
    onBlur(event)
  }

  const updateStateValue = useCallback(() => {
    const hkidValue: Array<string> = Array.from(`${value}`)
    const firstEngCharater = hkidValue.filter((x, i) => i < 2 && /^[a-zA-Z]/.test(x)).join('')
    const digit = hkidValue
      .filter((_x, i) => i + 1 > firstEngCharater.length && i < 7 + firstEngCharater.length)
      .join('') //max digit 7
    setBeforeParenthesis(firstEngCharater + digit.substr(0, 6))
    setInParenthesis(digit.substr(6, 7))
  }, [value])

  useEffect(() => {
    updateStateValue()
  }, [updateStateValue])

  return (
    <>
      <FlexContainer justifyContent={'flex-start'} alignItems={'center'} flexDirection={'row'}>
        <HKidBeforeParenthesisContainer>
          {' '}
          <InputField
            type="text"
            name={name}
            label={label}
            activeColor={true}
            onChange={handleHkidBeforeParenthesisChange}
            onBlur={handleHkidBlur}
            onFocus={() =>
              trackEvent(GTM_EVENT.customEvent, GTM_ACTION.formInput, 'name: hkid_number')
            }
            value={beforeParenthesis}
            error={error}
            maxLength={9}
            data-test="hkidBeforeParenthesis"
          />
        </HKidBeforeParenthesisContainer>
        <HKidInParenthesisContainer isOnError={error ? true : false}>
          <LeftParenthesis>(</LeftParenthesis>
          <HKidInParenthesisInput>
            <InputField
              type="text"
              name={name}
              padding={'0 16px'}
              fontSize={'18px'}
              textAlign={'center'}
              onBlur={handleHkidBlur}
              onFocus={() =>
                trackEvent(GTM_EVENT.customEvent, GTM_ACTION.formInput, 'name: hkid_parenthesis')
              }
              value={inParenthesis}
              onChange={handleHkidInParenthesisChange}
              maxLength={1}
              data-test="hkidInParenthesis"
            />
          </HKidInParenthesisInput>
          <RightParenthesis>)</RightParenthesis>
        </HKidInParenthesisContainer>
      </FlexContainer>
    </>
  )
}
export default HkidInput
