import { i18n } from '@src/lib/i18n'
import { AppActionTypes, InitialState } from '@src/types/store/app'

export const CHANGE_INIT = 'CHANGE_INIT'
export const CHANGE_APP_READY = 'CHANGE_APP_READY'
export const CHANGE_PAGE_READY = 'CHANGE_PAGE_READY'
export const CHANGE_OPTION_READY = 'CHANGE_OPTION_READY'
export const CHANGE_USER_READY = 'CHANGE_USER_READY'
export const CHANGE_DECLARATION_READY = 'CHANGE_DECLARATION_READY'
export const CHANGE_LOADING = 'CHANGE_LOADING'
export const CHANGE_ORDER = 'CHANGE_ORDER'
export const TOGGLE_POP_UP = 'TOGGLE_POP_UP'

export const INITIAL_STATE: InitialState = {
  ready: false,
  option: false,
  user: false,
  page: false,
  loading: { isShow: false, message: i18n.t('loading') },
  order: false,
  declaration: false,
  popUp: { isShow: false, type: '' }
}

export default function appReducer(state = INITIAL_STATE, action: AppActionTypes): InitialState {
  switch (action.type) {
    case CHANGE_INIT:
      return {
        ...state
      }
    case CHANGE_APP_READY:
      return {
        ...state,
        ready: true
      }
    case CHANGE_PAGE_READY:
      return {
        ...state,
        page: true
      }
    case CHANGE_OPTION_READY:
      return {
        ...state,
        option: true
      }
    case CHANGE_USER_READY:
      return {
        ...state,
        user: true
      }
    case CHANGE_DECLARATION_READY:
      return {
        ...state,
        declaration: true
      }
    case CHANGE_LOADING:
      return {
        ...state,
        loading: action.payload.loading
      }
    case CHANGE_ORDER:
      return {
        ...state,
        order: true
      }
    case TOGGLE_POP_UP:
      return {
        ...state,
        popUp: action.payload.popUp
      }
    default:
      return state
  }
}
