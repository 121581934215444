import { changeError, triggerErrorRedirect } from '@src/actions/errorActions'
import { MessageDialog } from '@src/components/MessageDialog'
import { i18n } from '@src/lib/i18n'
import { RootState } from '@src/reducers/rootReducer'
import {
  Dialog,
  Flex,
  Text,
  TextButton,
  Title,
  colors
} from '@welend/apple-shopping-cart-ui-library'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

export const ErrorNotifier = (): JSX.Element => {
  const errorData = useSelector((state: RootState) => state.error)
  const {
    mallState: { errorCallbackURI }
  } = useSelector((state: RootState) => state.mall)
  const { ajaxErrorResponseCode } = useSelector((state: RootState) => state.error)
  const dispatch = useDispatch()

  const handleBack = () => {
    dispatch(changeError({ display: false }))
    errorData?.buttonCallBackList?.map((x) => dispatch(x))
    if (ajaxErrorResponseCode === 401) dispatch(triggerErrorRedirect())
  }
  let message = ''
  if (errorData.message) {
    message = errorData.message
  } else if (errorData.message === '') {
    message = i18n.t('error:500_description')
  }
  let buttonText = ''
  if (errorData.button) {
    buttonText = errorData.button
  } else if (errorData.button === '') {
    buttonText = i18n.t('error:close')
  }
  const title = errorData.title || ''

  return (
    <MessageDialog
      isOpen={!!errorData.display}
      title={title}
      content={message}
      buttonText={buttonText}
      onClose={handleBack}
      icon={'error'}
    />
  )
}

export default ErrorNotifier
