import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
`

const Body = ({ children }) => {
  return <Container>{children}</Container>
}

export default Body
