import { getTempToken } from '@src/actions/authActions'
import config from '@src/config'
import { RootState } from '@src/reducers/rootReducer'
import QRCode from 'qrcode.react'
import querystring, { ParsedUrlQueryInput } from 'querystring'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
interface Props {
  path: string
  query?: ParsedUrlQueryInput
}

const UploadQRCode = (props: Props) => {
  const { path } = props
  const { APP_HOST } = config
  const dispatch = useDispatch()
  const { tempToken, tempTokenExpireAt, state, accessToken, expiresIn, expiresAt } = useSelector(
    (state: RootState) => state.auth
  )

  useEffect(() => {
    dispatch(getTempToken())
  }, [dispatch])

  if (!tempToken) {
    return <div></div>
  }
  return (
    <QRCode
      value={`${APP_HOST}${path}/token#state=${state}&access_token=${accessToken}&expires_in=${expiresIn}&expires_at=${expiresAt}`}
      renderAs="svg"
      size={200}
    />
  )
}

export default UploadQRCode
