import { togglePopUp } from '@src/actions/appActions'
import { updateLang } from '@src/actions/userActions'
import config from '@src/config'
import { LOAN_FORM_PATH } from '@src/lib/constant'
import { basePath, generateTncLink } from '@src/lib/helper'
import { Trans, i18n } from '@src/lib/i18n'
import { RootState } from '@src/reducers/rootReducer'
import { Copyright, Footer, Link, colors } from '@welend/apple-shopping-cart-ui-library'
import { useRouter } from 'next/router'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  color: ${colors.orange} !important;
`

const FooterComponent = (): JSX.Element => {
  const { APPLE_SHOPPING_CART_HOST } = config
  const router = useRouter()
  const dispatch = useDispatch()
  const { locale = i18n.language } = router.query
  const {
    location: { pathname }
  } = useSelector((state: RootState) => state.router)
  const {
    mallState: { theme }
  } = useSelector((state: RootState) => state.mall)

  const footerLink = [
    {
      uri: basePath('privacy-policy', i18n.language),
      text: i18n.t('footer:privacy_policy')
    },
    {
      uri: generateTncLink(theme),
      text: i18n.t('footer:terms_and_conditions'),
      type: 'resource'
    }
  ]

  const onError: Array<LOAN_FORM_PATH> = [LOAN_FORM_PATH.generalReject, LOAN_FORM_PATH.error]

  const isErrorPage = onError.find((x) => x === pathname)

  const hyperLink = (x, index): JSX.Element => (
    <React.Fragment key={'footerHyperLink' + index}>
      <a href={x.uri} target="_blank" rel="noreferrer">
        {x.text}
      </a>
    </React.Fragment>
  )

  const contactInfo = (
    <Trans
      i18nKey="footer:contact_us"
      components={[
        <StyledLink
          key={'subscription-information'}
          href={`${APPLE_SHOPPING_CART_HOST}${
            theme && `/${theme}`
          }/${locale}/subscription-information`}
          target="_blank"
        />
      ]}
    />
  )

  const FooterContentOptimized = {
    ...(isErrorPage ? { contactInfo, contactUsCopy: i18n.t('footer:contact_us_info_text') } : {}),
    copyright: i18n.t('footer:copyright_content'),
    footerLinks: footerLink.map(hyperLink),
    powered: i18n.t('footer:powered'),
    moneyLenderLicense: i18n.t('footer:money_lender_license', {
      license: config.MONEY_LENDER_LICENSE,
      interpolation: { escapeValue: false }
    }),
    langSwitch: (
      <Link
        onClick={() => {
          dispatch(updateLang(i18n.language === 'en' ? 'tc' : 'en'))
        }}>
        {i18n.t('footer:langSwitch')}
      </Link>
    )
  }

  const LoanLenderWarning = {
    contentLine: i18n.t('common:lender_warning')
  }

  const WelendServiceContent = {
    hotline: i18n.t('footer:hotline'),
    officeTime: i18n.t('footer:office_time')
  }

  return (
    <>
      <Footer
        content={FooterContentOptimized}
        loanLenderWarning={LoanLenderWarning}
        welendServiceContent={WelendServiceContent}
        contactUsToggle={() =>
          dispatch(
            togglePopUp({
              isShow: true,
              type: 'contactUs'
            })
          )
        }
      />
    </>
  )
}

export default FooterComponent
