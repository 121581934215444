import { i18n } from '@src/lib/i18n'
import { useProgress } from '@src/utils/useProgress'
import {
  Flex,
  PaymentError,
  SubTitle,
  Text,
  Title,
  colors
} from '@welend/apple-shopping-cart-ui-library'
import { NextPage } from 'next'
import { useDispatch } from 'react-redux'

const ErrorPage: NextPage = () => {
  const dispatch = useDispatch()
  useProgress({ dispatch, progress: { stepNames: [] } })
  return (
    <>
      <Flex mt="24" desktopMT="42" ml="6" mr="6" justifyContent="center" alignItems="center">
        <SubTitle align="center" fontWeight="bold" typeface="large" desktopCenter>
          <PaymentError width="64px" height="64px" color={colors.orange} />
        </SubTitle>
      </Flex>

      <Flex mt="4" ml="6" mr="6" justifyContent="center" alignItems="center">
        <Title align="center" fontWeight="bold" typeface="large" desktopCenter>
          {i18n.t('error:title')}
        </Title>
      </Flex>

      <Flex mt="4" mb="24" desktopMB="42" ml="6" mr="6" justifyContent="center" alignItems="center">
        <Text align="center" typeface="large" desktopCenter>
          {i18n.t('error:description')}
        </Text>
      </Flex>
    </>
  )
}

ErrorPage.getInitialProps = async () => {
  const progress = {
    currentStep: 1,
    stepNames: [],
    title: ''
  }
  return { progress }
}

export default ErrorPage
