import { DocumentActionTypes, InitialState } from '@src/types/store/document'
import { camelize } from 'humps'

export const UPLOAD_PROOF = 'UPLOAD_PROOF'
export const UPLOAD_PROOF_SUCCESS = 'UPLOAD_PROOF_SUCCESS'
export const UPLOAD_PROOF_FAILURE = 'UPLOAD_PROOF_FAILURE'
export const UPLOAD_PROOF_CLEAR = 'UPLOAD_PROOF_CLEAR'

export const INITIAL_STATE: InitialState = {
  hkidProof: null
}

export default function documentReducer(
  state: InitialState = INITIAL_STATE,
  action: DocumentActionTypes
): InitialState {
  switch (action.type) {
    case UPLOAD_PROOF_SUCCESS: {
      return {
        ...state,
        [camelize(action.payload.docType)]: action.payload.url
      }
    }
    case UPLOAD_PROOF_FAILURE:
      return {
        ...state
      }
    case UPLOAD_PROOF_CLEAR:
      return INITIAL_STATE
    default:
      return state
  }
}
