import config from '@src/config'
import { WARNING_ROUTES } from '@src/lib/constant'
import { merge } from 'lodash'

export function isMobile(userAgent?: string): boolean {
  if (typeof window !== 'undefined' || userAgent) {
    const testUserAgent = userAgent || navigator.userAgent
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(testUserAgent)
  }
  return false
}

/**
 * To remove all the undefined/null values in object (nested)
 * values in array remain
 * @param src object input, source object will not change
 *
 */
export function compact<T = Record<string, unknown>>(src: Record<string, unknown>): T {
  const clone = merge({}, src)
  Object.keys(clone).forEach((k) => {
    const item = clone[k]
    if (clone[k] === null || clone[k] == undefined) delete clone[k]
    else if (typeof item == 'object' && !Array.isArray(item)) {
      clone[k] = compact(clone[k] as Record<string, unknown>)
    }
  })
  return clone as T
}

export function convertMonthsToMonthsAndYear(months: number) {
  return { years: Math.floor(months / 12), months: months % 12 }
}

/**
 * To convert all property values in an object from
 * tyoe null to type string
 *
 */
export function toString(o) {
  Object.keys(o).forEach((k) => {
    if (o[k] === null) {
      o[k] = ''
    }
  })
  return o
}

export function basePath(path: string, targetLocale = 'en'): string {
  const { WELEND_SITE3_URL } = config

  return `${WELEND_SITE3_URL ? WELEND_SITE3_URL + '/' : ''}${
    targetLocale !== 'en' ? '' : targetLocale + '/'
  }${path}`
}

export const generateTncLink = (theme = 'default'): string => {
  const { STATIC_CONTENT_URL, APPLE_SHOPPING_CART_HOST } = config
  return `${STATIC_CONTENT_URL}/${theme}/docs/latest/terms-and-conditions.pdf`
}

export const getWarningRouter = (pathname: string) => {
  return WARNING_ROUTES.some((route) => route === pathname)
}
