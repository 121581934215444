import styled, { keyframes } from 'styled-components'

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  display: inline-block;
  vertical-align: middle;
  animation: ${rotate360} 0.4s linear infinite;
  transform: translateZ(0);

  border-top: ${(props) =>
    props.theme?.welabPayUi?.alertBorderColor
      ? `2px solid ${props.theme.welabPayUi.alertBorderColor};`
      : '2px solid #ffb300;'}
  border-right: ${(props) =>
    props.theme?.welabPayUi?.alertBorderColor
      ? `2px solid ${props.theme.welabPayUi.alertBorderColor};`
      : '2px solid #ffb300;'}
  border-bottom: ${(props) =>
    props.theme?.welabPayUi?.alertBorderColor
      ? `2px solid ${props.theme.welabPayUi.alertBorderColor};`
      : '2px solid #ffb300;'};
  border-left: 4px solid white;
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`

export default Spinner
