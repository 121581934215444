import { i18n } from '@src/lib/i18n'
import { KbaQuestion } from '@src/types/store/kba'
import { Button, Flex, Radio } from '@welend/apple-shopping-cart-ui-library'
import React, { useState } from 'react'
import styled from 'styled-components'

interface Props {
  question: KbaQuestion
  currentIndex: number
  index: number
  total: number
  onKbaAnswered: (questionId: string | number, answerId: string | number) => unknown
}

const KbaChoice = styled.div`
  display: grid;
  grid-auto-flow: row;
  row-gap: 12px;
`

const KbaQuestionField = (props: Props) => {
  const { currentIndex, index, total, question } = props
  const [selectedAnswer, setSelectedAnswer] = useState<string | number>(-1)
  if (currentIndex != index) {
    return <></>
  }
  const renderNextButton = () => (
    <Button
      color="primary"
      variant="contained"
      fullWidth
      type="submit"
      disabled={selectedAnswer == -1}
      onClick={confirmKbaAnswerSelection}>
      {index + 1 === total ? i18n.t('button_submit') : i18n.t('button_next')}
    </Button>
  )
  const handleKbaSelect = (event) => {
    setSelectedAnswer(event.target.value)
  }

  const confirmKbaAnswerSelection = () => {
    props.onKbaAnswered(question.questionId, selectedAnswer)
  }

  return (
    <>
      <Flex>
        <div>
          {i18n.t('kba:kba_question_count_prefix', {
            count: index + 1,
            total,
            context: question.questionText
          })}
        </div>
      </Flex>
      <KbaChoice>
        {question.choices.map((c, i) => (
          <Radio
            name="choice"
            id={i}
            label={c}
            key={i}
            value={i}
            checked={selectedAnswer == i}
            onClick={handleKbaSelect}
          />
        ))}
      </KbaChoice>
      {renderNextButton()}
    </>
  )
}

export default KbaQuestionField
