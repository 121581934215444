import * as types from '@src/reducers/errorReducer'
import {
  ChangeErrorAction,
  ChangeErrorCompleteAction,
  Error,
  TriggerErrorRedirectAction
} from '@src/types/store/error'

export const changeError = (errorResponse: Error): ChangeErrorAction => ({
  type: types.CHANGE_ERROR,
  payload: {
    ...errorResponse
  }
})

export const changeErrorComplete = (): ChangeErrorCompleteAction => ({
  type: types.CHANGE_ERROR_COMPLETE
})

export const triggerErrorRedirect = (): TriggerErrorRedirectAction => ({
  type: types.ERROR_REDIRECT
})
