import { KbaQuestion } from '@src/types/store/kba'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import KbaQuestionField from './KbaQuestionField'

interface Props {
  questions: KbaQuestion[]
  questionReady: boolean
  onKbaAnswered: (questionId: string | number, answerId: string | number) => unknown
  onComplete: () => unknown
}

const KbaQuestionsContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  row-gap: 24px;
  margin-left: 32px;
  margin-right: 32px;
`

const KbaQuestions = (props: Props) => {
  const { questionReady, questions, onComplete, onKbaAnswered } = props
  const [currentIndex, setCurrentIndex] = useState<number>(-1)
  const totalQuestionCount = questions.length
  useEffect(() => {
    if (questionReady && currentIndex == -1) {
      setCurrentIndex(0)
    }
  }, [questionReady, currentIndex])
  useEffect(() => {
    if (questionReady && currentIndex + 1 > totalQuestionCount) {
      onComplete()
    }
  }, [questionReady, currentIndex, onComplete, totalQuestionCount])

  useEffect(() => {
    // initialise form for 2nd attempt
    setCurrentIndex(-1)
  }, [questions])
  const handleKbaAnswer = (questionId: string | number, answerId: string | number) => {
    setCurrentIndex(currentIndex + 1)
    onKbaAnswered(questionId, answerId)
  }
  return (
    <KbaQuestionsContainer>
      {questions.map((q, i) => (
        <KbaQuestionField
          key={`kba_question_${q.questionId}`}
          question={q}
          currentIndex={currentIndex}
          index={i}
          total={totalQuestionCount}
          onKbaAnswered={handleKbaAnswer}
        />
      ))}
    </KbaQuestionsContainer>
  )
}

export default KbaQuestions
