import * as types from '@src/reducers/documentReducer'
import {
  DocumentRespoonse,
  UploadProof,
  UploadProofAction,
  UploadProofClearAction,
  UploadProofFailureAction,
  UploadProofSuccessAction
} from '@src/types/store/document'

export const uploadProof = (
  documentType: UploadProof['documentType'],
  file: UploadProof['file']
): UploadProofAction => ({
  type: types.UPLOAD_PROOF,
  payload: { documentType, file }
})

export const uploadProofSuccess = (documentData: DocumentRespoonse): UploadProofSuccessAction => ({
  type: types.UPLOAD_PROOF_SUCCESS,
  payload: documentData
})

export const uploadProofFailure = (): UploadProofFailureAction => ({
  type: types.UPLOAD_PROOF_FAILURE
})

export const uploadProofClear = (): UploadProofClearAction => ({
  type: types.UPLOAD_PROOF_CLEAR
})
