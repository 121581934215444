import { Error, ErrorActionTypes } from '@src/types/store/error'

export const CHANGE_ERROR = 'CHANGE_ERROR'
export const CHANGE_ERROR_COMPLETE = 'CHANGE_ERROR_COMPLETE'

export const ERROR_REDIRECT = 'ERROR_REDIRECT'

export const INITIAL_STATE: Error = {
  redirect: false,
  display: false,
  title: '',
  message: '',
  icon: 'aware',
  button: 'back',
  contacts: '',
  gaTagEventLabel: '',
  ajaxErrorResponseCode: null,
  buttonCallBackList: []
}

export default function appReducer(state = INITIAL_STATE, action: ErrorActionTypes) {
  switch (action.type) {
    case CHANGE_ERROR:
      return {
        ...INITIAL_STATE,
        ...action.payload
      }
    default:
      return state
  }
}
