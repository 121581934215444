import * as types from '@src/reducers/appReducer'
import {
  ChangeAppReadyAction,
  ChangeDeclarationReadyAction,
  ChangeInitAction,
  ChangeLoadingAction,
  ChangeOptionReadyAction,
  ChangeOrderAction,
  ChangePageReadyAction,
  ChangeUserReadyAction,
  Loading,
  PopUp,
  TogglePopUpAction
} from '@src/types/store/app'

export const changeInit = (): ChangeInitAction => ({
  type: types.CHANGE_INIT
})

export const changePageReady = (): ChangePageReadyAction => ({
  type: types.CHANGE_PAGE_READY
})

export const changeAppReady = (): ChangeAppReadyAction => ({
  type: types.CHANGE_APP_READY
})

export const changeOptionReady = (): ChangeOptionReadyAction => ({
  type: types.CHANGE_OPTION_READY
})

export const changeUserReady = (): ChangeUserReadyAction => ({
  type: types.CHANGE_USER_READY
})

export const changeLoading = (loading: Loading): ChangeLoadingAction => ({
  type: types.CHANGE_LOADING,
  payload: { loading: loading }
})

export const changeOrder = (): ChangeOrderAction => ({
  type: types.CHANGE_ORDER
})

export const changeDeclarationReady = (): ChangeDeclarationReadyAction => ({
  type: types.CHANGE_DECLARATION_READY
})

export const togglePopUp = (popUp: PopUp): TogglePopUpAction => ({
  type: types.TOGGLE_POP_UP,
  payload: { popUp }
})
