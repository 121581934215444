import { AuthActionTypes, InitialState } from '@src/types/store/auth'

export const LOGIN_REDIRECTED = 'LOGIN_REDIRECTED'
export const LOGIN_STORE_TOKEN = 'LOGIN_STORE_TOKEN'
export const LOGIN_DO_OAUTH = 'LOGIN_DO_OAUTH'
export const LOGIN_USER = 'LOGIN_USER'

export const GET_TEMP_TOKEN = 'GET_TEMP_TOKEN'
export const GET_TEMP_TOKEN_SUCCESS = 'GET_TEMP_TOKEN_SUCCESS'
export const GET_TEMP_TOKEN_FAILURE = 'GET_TEMP_TOKEN_FAILURE'

export const WITH_TOKEN = 'WITH_TOKEN'

export const CLEAR_AUTH = 'CLEAR_AUTH'

export const INITIAL_STATE: InitialState = {
  accessToken: '',
  expiresIn: '',
  expiresAt: 0,
  state: '',
  tokenType: '',
  tempToken: '',
  tempTokenExpireAt: '',
  withToken: false
}

export default function authReducer(state = INITIAL_STATE, action: AuthActionTypes): InitialState {
  switch (action.type) {
    case LOGIN_STORE_TOKEN:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        expiresIn: action.payload.expiresIn,
        expiresAt: action.payload.expiresAt,
        state: action.payload.state
      }
    case GET_TEMP_TOKEN_SUCCESS:
      return {
        ...state,
        tempToken: action.payload.tempToken,
        tempTokenExpireAt: action.payload.expireAt
      }
    case GET_TEMP_TOKEN_FAILURE:
      return {
        ...state
      }
    case WITH_TOKEN:
      return {
        ...state,
        withToken: true
      }
    case CLEAR_AUTH:
      return INITIAL_STATE
    default:
      return state
  }
}
