import * as types from '@src/reducers/userReducer'
import {
  ChangeLangAction,
  ChangeLangFailureAction,
  ChangeLangSuccessAction,
  GetUserAction,
  GetUserFailureAction,
  GetUserSuccessAction,
  UpdateLangAction,
  UpdateLangSuccessAction,
  UpdateUserAgent,
  UpdateUserState,
  User,
  UserAgent
} from '@src/types/store/user'

export const changeLang = (): ChangeLangAction => ({
  type: types.CHANGE_LANG
})

export const updateLang = (lang: string): UpdateLangAction => ({
  type: types.UPDATE_LANG,
  payload: { lang }
})

export const updateLangSuccess = (lang: string): UpdateLangSuccessAction => ({
  type: types.UPDATE_LANG_SUCCESS,
  payload: { lang }
})

export const changeLangSuccess = (): ChangeLangSuccessAction => ({
  type: types.CHANGE_LANG_SUCCESS
})

export const changeLangFailure = (): ChangeLangFailureAction => ({
  type: types.CHANGE_LANG_FAILURE
})

export const updateUserAgent = (userAgent: UserAgent): UpdateUserAgent => ({
  type: types.UPDATE_USER_AGENT,
  payload: { userAgent }
})

export const updateUserState = (userState: string): UpdateUserState => {
  return {
    type: types.UPDATE_USER_STATE,
    payload: { userState }
  }
}

export const getUser = (): GetUserAction => ({
  type: types.GET_USER
})

export const getUserSuccess = (user: User): GetUserSuccessAction => ({
  type: types.GET_USER_SUCCESS,
  payload: { user }
})

export const getUserFailure = (): GetUserFailureAction => ({
  type: types.GET_USER_FAILURE
})
